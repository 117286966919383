import { useState } from 'react'
import { doc, updateDoc, getDoc, serverTimestamp } from "firebase/firestore"
import { useZxing } from "react-zxing"
import { CheckIcon, NoSymbolIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useParams } from 'react-router-dom'

export default function Registrations({ db }) {
    const { eventId } = useParams()
    const [lastScanned, setLastScanned] = useState(null)
    const [isMidScan, setIsMidScan] = useState(false)
    const [animationClass, setAnimationClass] = useState("hidden")

    const { ref } = useZxing({
        onDecodeResult(result) {
            attemptRedeem(result.getText())
        },
        paused: false
    })

    const playBeep = (frequency = 520, duration = 100) => {
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
        const oscillator = audioCtx.createOscillator()
        oscillator.type = 'square'
        oscillator.frequency.setValueAtTime(frequency, audioCtx.currentTime)
        oscillator.connect(audioCtx.destination)
        oscillator.start()
        oscillator.stop(audioCtx.currentTime + duration / 1000)
    }

    const playErrorBeep = (frequency = 260, duration = 100, count = 2, gap = 50) => {
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
        let startTime = audioCtx.currentTime
        for (let i = 0; i < count; i++) {
            const oscillator = audioCtx.createOscillator()
            oscillator.type = 'square'
            oscillator.frequency.setValueAtTime(frequency, startTime)
            oscillator.connect(audioCtx.destination)
            oscillator.start(startTime)
            oscillator.stop(startTime + duration / 1000)
            startTime += (duration + gap) / 1000
        }
    }

    const attemptRedeem = async (id) => {
        if (lastScanned && lastScanned.id === id) {
            return
        } else {
            setIsMidScan(true)
            try {
                const docRef = doc(db, "tickets", id)
                const docSnap = await getDoc(docRef)
                const promises = []
                if (docSnap.exists) {
                    const docData = docSnap.data()
                    if (docData.redeemed) {
                        setLastScanned({
                            id: docSnap.id,
                            data: docData,
                            icon: NoSymbolIcon,
                            color: 'red',
                            status: 'invalid'
                        })
                        playErrorBeep()
                    } else if (docData.refundDate) {
                        setLastScanned({
                            id: docSnap.id,
                            data: docData,
                            icon: ExclamationTriangleIcon,
                            color: 'yellow',
                            status: 'refunded'
                        })
                        playErrorBeep()
                    } else if (docData.paymentLinkId !== eventId) {
                        setLastScanned({
                            id: docSnap.id,
                            data: docData,
                            icon: ExclamationTriangleIcon,
                            color: 'yellow',
                            status: 'wrong-event'
                        })
                        playErrorBeep()
                    } else if (docData.selectedDate) {
                        let slotStart
                        let slotEnd
                        let startTime = '00:00:00'
                        let endTime = '00:00:00'
                        if (docData.selectedTimeSlot) {
                            const timeParts = docData.selectedTimeSlot.split(" - ")
                            if (timeParts[0].includes("am")) {
                                const parts = timeParts[0].trim().slice(0, -2).trim().split(":")
                                startTime = parts[0].padStart(2, '0') + ":" + (parts[1] || "00") + ":00"
                            } else if (timeParts[0].includes("pm")) {
                                const parts = timeParts[0].trim().slice(0, -2).trim().split(":")
                                const newPart0 = parseInt(parts[0]) + 12
                                startTime = newPart0 + ":" + (parts[1] || "00") + ":00"
                            }
                            if (timeParts[1] && timeParts[1].includes("am")) {
                                const parts = timeParts[1].trim().slice(0, -2).trim().split(":")
                                endTime = parts[0].padStart(2, '0') + ":" + (parts[1] || "00") + ":00"
                            } else if (timeParts[1] && timeParts[1].includes("pm")) {
                                const parts = timeParts[1].trim().slice(0, -2).trim().split(":")
                                const newPart0 = parseInt(parts[0]) + 12
                                endTime = newPart0 + ":" + (parts[1] || "00") + ":00"
                            }
                        }
                        slotStart = new Date(`${docData.selectedDate}T${startTime}`)
                        slotEnd = new Date(`${docData.selectedDate}T${endTime}`)
                        const now = new Date()
                        if (now < slotStart || now > slotEnd) {
                            setLastScanned({
                                id: docSnap.id,
                                data: docData,
                                icon: ExclamationTriangleIcon,
                                color: 'yellow',
                                status: 'wrong-slot'
                            })
                            playErrorBeep()
                        } else {
                            setLastScanned({
                                id: docSnap.id,
                                data: docData,
                                icon: CheckIcon,
                                color: 'green',
                                status: "valid"
                            })
                            playBeep()
                            promises.push(updateDoc(docRef, {
                                redeemed: true,
                                mostRecentRedemptionChange: serverTimestamp()
                            }))
                        }
                    } else {
                        setLastScanned({
                            id: docSnap.id,
                            data: docData,
                            icon: CheckIcon,
                            color: 'green',
                            status: "valid"
                        })
                        playBeep()
                        promises.push(updateDoc(docRef, {
                            redeemed: true,
                            mostRecentRedemptionChange: serverTimestamp()
                        }))
                    }
                    setAnimationClass('fade-in-scale-up')
                    setTimeout(() => {
                        setAnimationClass('fade-out-scale-down')
                    }, 1000)
                    setIsMidScan(false)
                    await Promise.all(promises)
                }
            } catch (error) {
                setIsMidScan(false)
            }
        }
    }

    const redeemAnyway = async (id) => {
        setIsMidScan(true)
        const docRef = doc(db, "tickets", id)
        await updateDoc(docRef, {
            redeemed: true,
            mostRecentRedemptionChange: serverTimestamp()
        })
        playBeep()
        setLastScanned(lastScanned => ({
            ...lastScanned,
            status: "valid",
            color: "green"
        }))
        setIsMidScan(false)
    }

    return (
        <div className="touch-none h-screen bg-white dark:bg-black">
            <video ref={ref} className="scanner" />
            <div className="placeholder">
                {lastScanned &&
                    <div className={`status-container ${animationClass} ${lastScanned.color}`}>
                        <lastScanned.icon className="status-icon" style={{ color: lastScanned.color }} />
                    </div>
                }
            </div>
            {!lastScanned && <p className="mx-4 h-[36vh] flex justify-center items-center border-dashed border-2 border-neutral-500 dark:border-neutral-400 rounded-lg mt-4">Ready to scan...</p>}
            {lastScanned &&
                <div className={`border-2 border-neutral-500 dark:border-neutral-400 border-dashed rounded p-3 mt-2 mx-4 bg-neutral-50 dark:bg-neutral-900`}>
                    {lastScanned.color === "red" &&
                        <div className="text-white flex justify-between items-center bg-red-800 p-2 rounded mb-2">
                            <p>Previously redeemed {lastScanned.data.mostRecentRedemptionChange.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</p>
                            <NoSymbolIcon className='h-8 w-8' style={{ color: "white" }} />
                        </div>
                    }
                    {lastScanned.color === "green" &&
                        <div className="text-white flex justify-between items-center bg-green-800 p-2 rounded mb-2">
                            <p>Valid for entry</p>
                            <CheckIcon className='h-8 w-8' style={{ color: "white" }} />
                        </div>
                    }
                    {lastScanned.color === "yellow" &&
                        <>
                            <div className="text-white flex justify-between items-center bg-yellow-800 p-2 rounded mb-2">
                                {lastScanned.status === "refunded" &&
                                    <p>Refunded on {lastScanned.data.refundDate && lastScanned.data.refundDate.toDate().toLocaleString()}</p>
                                }
                                {lastScanned.status === "wrong-event" &&
                                    <p>Different one of your events</p>
                                }
                                {lastScanned.status === "wrong-slot" &&
                                    <p>Not the current time slot</p>
                                }
                                <ExclamationTriangleIcon className='h-8 w-8' style={{ color: "white" }} />
                            </div>
                            <button
                                type="button"
                                onClick={() => redeemAnyway(lastScanned.id)}
                                className="rounded p-2 font-medium border border-neutral-900 dark:border-neutral-100 w-full mb-2"
                                disabled={isMidScan}
                            >
                                {isMidScan ? "Redeeming..." : "Redeem Anyway"}
                            </button>
                        </>
                    }
                    <p className="font-medium">{lastScanned.data.eventName}, {lastScanned.data.description}</p>
                    {lastScanned.data.selectedDate &&
                        <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{lastScanned.data.selectedDate} {lastScanned.data.selectedTimeSlot && lastScanned.data.selectedTimeSlot}</p>
                    }
                    <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{lastScanned.data.buyerName}</p>
                    <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{lastScanned.data.buyerEmail}</p>
                    <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{lastScanned.id}</p>
                </div>
            }
        </div>
    )
}