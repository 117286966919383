import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { completeSignIn } from '../../auth/AuthService';

export function FinalizeSignIn() {
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const finishSignIn = async () => {
            try {
                const user = await completeSignIn();

                if (user) {
                    const redirectTo = window.localStorage.getItem('redirectAfterAuth') || '/app';
                    window.localStorage.removeItem('redirectAfterAuth');
                    navigate(redirectTo);
                } else {
                    setError('Invalid or expired sign-in link. Please try again.');
                }
            } catch (err) {
                setError(err.message);
            }
        };

        finishSignIn();
    }, [navigate]);

    if (error) {
        return (
            <div className="min-h-screen py-24 lg:py-36">
                <div className="w-10/12 max-w-4xl mx-auto">
                    <img className="h-9 lg:h-12 mb-6 sidebar-logo" alt="sidebar-ticketing-logo" />
                    <div className="max-w-md">
                        <h2 className="text-4xl tracking-tight font-bold sm:text-7xl text-neutral-800 dark:text-neutral-100 mx-auto mb-8">
                            Oops!
                        </h2>
                        <div className="p-4 bg-red-50 border border-red-100 text-red-700 rounded-lg flex items-center mb-6">
                            {error}
                        </div>
                        <button
                            onClick={() => navigate('/signin')}
                            className="w-full py-3 px-4 rounded-lg text-white bg-teal-500 hover:bg-teal-600 
                transition duration-150 ease-in-out transform hover:scale-[1.02] 
                focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                        >
                            Try Again
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen py-24 lg:py-36">
            <div className="w-10/12 max-w-4xl mx-auto">
                <img className="h-9 lg:h-12 mb-6 sidebar-logo" alt="sidebar-ticketing-logo" />
                <h1 className="text-4xl tracking-tight font-bold sm:text-7xl mx-auto">
                    Almost there...
                </h1>
                <p className="mt-8 text-stone-600 dark:text-stone-400 text-lg">
                    Please wait while we complete the sign-in process.
                </p>
            </div>
        </div>
    );
} 