import { Navigate } from 'react-router-dom'

export default function AuthRequired() {
    // Store the current URL in case we want to redirect back after login
    const currentPath = window.location.pathname
    const searchParams = new URLSearchParams()
    searchParams.set('redirect', currentPath)

    return (
        <Navigate 
            to={`/signin?${searchParams.toString()}`} 
            replace={true} 
        />
    )
} 