import { useState } from 'react'
import Toast from './Toast'
import { TableCellsIcon, DocumentIcon } from '@heroicons/react/24/outline'

export default function DownloadAttendees({ 
    auth, 
    uid, 
    eventId, 
    showCustomFields,
    issued = 0,
    onDownloadStart,
    onDownloadComplete
}) {
    const [isLoadingDownload, setIsLoadingDownload] = useState(false)
    const [errorLoadingDownload, setErrorLoadingDownload] = useState(false)
    const [hasDownloaded, setHasDownloaded] = useState(false)

    const downloadPdf = async () => {
        setIsLoadingDownload(true);
        setErrorLoadingDownload(false);
        onDownloadStart?.();

        try {
            const timestamp = new Date().toLocaleString()
            const idToken = await auth.currentUser.getIdToken()
            const response = await fetch('https://api.sidebarticketing.com/f/get-pdf-attendee-list', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + idToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    accountId: uid,
                    eventIds: [eventId],
                    timestamp,
                    ...(showCustomFields && { includeCustomFields: true })
                }),
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'attendee_list.pdf'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)

            setHasDownloaded(true)
            setTimeout(() => setHasDownloaded(false), 3000)
        } catch (error) {
            console.error('Download failed:', error)
            setErrorLoadingDownload(true)
        } finally {
            setIsLoadingDownload(false)
            onDownloadComplete?.()
        }
    }

    const downloadCsv = async () => {
        setIsLoadingDownload(true);
        setErrorLoadingDownload(false);
        onDownloadStart?.();

        try {
            const timestamp = new Date().toLocaleString()
            const idToken = await auth.currentUser.getIdToken()
            const response = await fetch('https://api.sidebarticketing.com/f/get-csv-tickets-list', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + idToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    accountId: uid,
                    eventIds: [eventId],
                    timestamp
                }),
            })
            if (response.status === 200) {
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = 'tickets.csv'
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
                setIsLoadingDownload(false)
                setHasDownloaded(true)
                setTimeout(() => {
                    setHasDownloaded(false)
                }, 3000)
            } else {
                setIsLoadingDownload(false)
                setErrorLoadingDownload(true)
            }
        } catch (error) {
            console.error('Download failed:', error)
            setErrorLoadingDownload(true)
        } finally {
            setIsLoadingDownload(false)
            onDownloadComplete?.()
        }
    }

    return (
        <>
            {hasDownloaded && <Toast isSuccess={true} message="Check your downloads folder" />}
            {errorLoadingDownload && <Toast isSuccess={false} message="Download failed" />}
            <button 
                onClick={downloadCsv} 
                className={`flex items-center px-4 py-2 text-sm w-full ${
                    issued > 0 
                        ? 'hover:bg-stone-100' 
                        : 'cursor-not-allowed opacity-50'
                }`}
                disabled={isLoadingDownload || issued === 0}
            >
                <TableCellsIcon className="h-5 w-5 mr-3" />
                {isLoadingDownload ? 'Downloading...' : 'Download CSV'}
            </button>
            <button 
                onClick={downloadPdf}
                className={`flex items-center px-4 py-2 text-sm w-full ${
                    issued > 0 
                        ? 'hover:bg-stone-100' 
                        : 'cursor-not-allowed opacity-50'
                }`}
                disabled={isLoadingDownload || issued === 0}
            >
                <DocumentIcon className="h-5 w-5 mr-3" />
                {isLoadingDownload ? 'Downloading...' : 'Download PDF'}
            </button>
        </>
    )
}
