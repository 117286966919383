import { useEffect } from 'react'
import sbIcon from './images/sidebar-icon-192.png'

export default function AuthLoading({ authenticateWith }) {
    useEffect(() => {
        if (authenticateWith) {
            authenticateWith().catch(error => {
                console.error('Authentication failed:', error)
            })
        }
    }, [authenticateWith])

    return (
        <div className="flex justify-center items-center h-screen">
            <img className="h-12 w-12" src={sbIcon} alt="sidebar-ticketing-logo" />
            <p>Loading Sidebar Ticketing...</p>
        </div>
    )
}