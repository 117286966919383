import { useState } from 'react'

export default function UserAccountIcon({ uid }) {
    const [imageError, setImageError] = useState(false)

    if (imageError) {
        return
    }

    return (
        <img 
            alt="" 
            src={`https://firebasestorage.googleapis.com/v0/b/sidebar-de524.appspot.com/o/account_images%2F${uid}_circle_100.png?alt=media`}
            className="size-10 rounded-full bg-gray-200" 
            onError={() => setImageError(true)}
        />
    )
}