import { TicketIcon, ExclamationTriangleIcon, ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon, HandThumbUpIcon, EllipsisVerticalIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import { Menu } from '@headlessui/react'
import { doc, updateDoc, serverTimestamp } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function Ticket({ ticket: t, now, db, showCustomFields, events, functions }) {
    const [showExchange, setShowExchange] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [selectedTicketType, setSelectedTicketType] = useState(null)
    const [isExchanging, setIsExchanging] = useState(false)
    const [exchangeError, setExchangeError] = useState(null)
    const [exchangeSuccess, setExchangeSuccess] = useState(false)

    const toggleRedeem = async () => {
        try {
            const docRef = doc(db, "tickets", t.ticketId)
            if (!docRef) {
                console.error('Failed to get document reference')
                return
            }
            
            await updateDoc(docRef, {
                redeemed: !t.redeemed,
                mostRecentRedemptionChange: serverTimestamp()
            })
        } catch (error) {
            console.error('Error toggling redeem status:', error)
        }
    }

    function formatCurrency(amount, currencyCode) {
        try {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyCode,
            })
            return formatter.format(amount / 100)
        } catch {
            return ""
        }
    }


    // where left off: you were moving the above stuff to ticket to get the URL. However maybe this is best done when querying tickets.

    const handleExchange = async () => {
        if (!selectedEvent || !selectedTicketType) return
        
        setIsExchanging(true)
        setExchangeError(null)
        try {
            const exchangeTicket = httpsCallable(functions, 'exchangeTicket')
            await exchangeTicket({
                newEventId: selectedEvent.paymentLinkId,
                oldEventId: t.paymentLinkId,
                oldProductId: t.productId,
                newProductId: selectedTicketType.productId,
                ticketId: t.ticketId,
                ticketDescription: selectedTicketType.name
            })
            setExchangeSuccess(true)
            // Close dialog after showing success message briefly
            setTimeout(() => {
                setShowExchange(false)
                setExchangeSuccess(false)
            }, 1500)
        } catch (error) {
            console.error('Exchange error:', error)
            setExchangeError('Failed to exchange ticket. Please try again.')
        } finally {
            setIsExchanging(false)
        }
    }

    return (
        <>
            <li className={`flex border rounded p-3 mt-2 border-stone-200 dark:border-stone-700 ${
                t.redeemed ? 'bg-stone-50 dark:bg-stone-800' : ''
            }`}>
                <div className="flex-grow relative">
                    <Menu as="div" className="absolute right-0 top-0">
                        <Menu.Button className="p-1 hover:bg-stone-100 dark:hover:bg-stone-800 rounded">
                            <EllipsisVerticalIcon className="h-5 w-5 text-stone-500 dark:text-stone-400" />
                        </Menu.Button>
                        <Menu.Items className="absolute right-0 mt-1 w-40 origin-top-right bg-white dark:bg-stone-800 border border-stone-200 dark:border-stone-700 rounded shadow-lg">
                            <Menu.Item>
                                {({ active }) => (
                                    <button 
                                        onClick={() => setShowExchange(true)}
                                        className={`${active ? 'bg-stone-100 dark:bg-stone-700' : ''} w-full text-left px-4 py-2 text-sm`}
                                    >
                                        Exchange
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button 
                                        onClick={() => t.url && window.open(t.url, '_blank')}
                                        className={`${active ? 'bg-stone-100 dark:bg-stone-700' : ''} w-full text-left px-4 py-2 text-sm`}
                                    >
                                        View ticket
                                    </button>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>
                    <span>
                        <p className={`mb-2 text-sm leading-relaxed text-stone-600 dark:text-stone-300 hidden sm:block`}>
                            {t.redeemed && t.mostRecentRedemptionChange ? (
                                <span>
                                    Redeemed at {(t.mostRecentRedemptionChange?.toDate?.() || new Date(t.mostRecentRedemptionChange)).toLocaleString()}
                                </span>
                            ) : (
                                <span>
                                    Not redeemed
                                </span>
                            )}
                        </p>
                        <p className="font-medium">{t.buyerName}</p>
                        <p className="text-sm text-stone-600 dark:text-stone-300 leading-relaxed">{t.buyerEmail}</p>
                        {t.selectedDate &&
                            <p className="text-sm text-stone-600 dark:text-stone-300 leading-relaxed flex flex-row gap-1 items-center">
                                {now < t.slotStart ? <ArrowLeftOnRectangleIcon className='h-4 w-4 text-yellow-600' /> : now > t.slotEnd ? <ArrowRightOnRectangleIcon className='h-4 w-4 text-yellow-600' /> : <HandThumbUpIcon className='h-4 w-4 text-green-600' />}{t.selectedDate} {t.selectedTimeSlot && t.selectedTimeSlot}
                            </p>
                        }
                        <p className="text-sm text-stone-600 dark:text-stone-300 leading-relaxed">{t.description}{t.amount === "bundle" ? " (purchased in a bundle)" : t.amount && t.currency && ` - ${formatCurrency(t.amount, t.currency)}`}</p>
                        {showCustomFields && t.customFields && t.customFields.filter(i => i.value).map((field, index) => (
                            <p key={index} className="text-sm text-stone-600 dark:text-stone-300  leading-relaxed">{field.label}: {field.value}</p>
                        ))}
                        <p className="text-sm text-stone-600 dark:text-stone-300 leading-relaxed">{t.id}</p>
                        {t.refundDate &&
                            <span className='flex items-center space-x-1'>
                                <ExclamationTriangleIcon className='h-4 w-4 text-yellow-600' />
                                <p className="text-sm text-yellow-600 leading-relaxed">Refunded on {(t.refundDate?.toDate?.() || new Date(t.refundDate)).toDateString()}</p>
                            </span>
                        }
                    </span>
                </div>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleRedeem();
                    }}
                    className={`ml-4 -m-3 pl-4 pr-6 border-l-2 border-dashed border-stone-200 dark:border-stone-800 text-sm min-w-[110px] flex flex-col items-center justify-center gap-1`}
                >
                    {t.redeemed ? (
                        <ArrowUturnLeftIcon className="h-5 w-5" />
                    ) : (
                        <TicketIcon className="h-5 w-5" />
                    )}
                    <span className="text-xs">{t.redeemed ? "Unredeem" : "Redeem"}</span>
                </button>
            </li>

            <Dialog open={showExchange} onClose={() => setShowExchange(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-sm rounded bg-white dark:bg-stone-900 p-6 w-full">
                        <div className="flex justify-between items-center mb-4">
                            <Dialog.Title className="text-lg font-medium">Exchange Ticket</Dialog.Title>
                            <button onClick={() => setShowExchange(false)} className="text-stone-500 dark:text-stone-400">
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>

                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-1">
                                    Select Event
                                </label>
                                <select
                                    className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                    value={selectedEvent?.paymentLinkId || ''}
                                    onChange={(e) => {
                                        const event = events?.find(ev => ev.paymentLinkId === e.target.value)
                                        setSelectedEvent(event)
                                        setSelectedTicketType(null)
                                    }}
                                >
                                    <option value="">Select an event...</option>
                                    {events?.filter(e => !e.archived).map(event => (
                                        <option key={event.paymentLinkId} value={event.paymentLinkId}>
                                            {event.eventName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {selectedEvent && (
                                <div>
                                    <label className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-1">
                                        Select Ticket Type
                                    </label>
                                    <select
                                        className="w-full rounded-md border-stone-300 dark:border-stone-700 dark:bg-stone-800"
                                        value={selectedTicketType?.productId || ''}
                                        onChange={(e) => {
                                            const ticketType = selectedEvent.ticketTypes?.find(p => p.productId === e.target.value)
                                            setSelectedTicketType(ticketType)
                                        }}
                                    >
                                        <option value="">Select a ticket type...</option>
                                        {selectedEvent.ticketTypes?.map(ticketType => (
                                            <option key={ticketType.productId} value={ticketType.productId}>
                                                {ticketType.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            {exchangeError && (
                                <p className="text-sm text-red-600 dark:text-red-400">
                                    {exchangeError}
                                </p>
                            )}
                            
                            {exchangeSuccess && (
                                <p className="text-sm text-green-600 dark:text-green-400">
                                    Ticket successfully exchanged!
                                </p>
                            )}

                            <button
                                onClick={handleExchange}
                                disabled={!selectedEvent || !selectedTicketType || isExchanging}
                                className="w-full bg-teal-600 text-white rounded-md py-2 px-4 text-sm font-medium hover:bg-teal-700 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isExchanging ? 'Exchanging...' : 'Confirm Exchange'}
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </>
    )
} 