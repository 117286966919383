import { useState, useEffect } from 'react'
import addToAppleWalletButton from './images/Add_to_Apple_Wallet.svg'
import addToGoogleWalletButton from './images/enUS_add_to_google_wallet_add-wallet-badge.svg'
import AccountIcon from './AccountIcon'

export default function Ticket() {
    const [ticketName, setTicketName] = useState("")
    const [ticketId, setTicketId] = useState("")
    const [ticketCode, setTicketCode] = useState("")
    const [eventName, setEventName] = useState("")
    const [locationName, setLocationName] = useState("")
    const [fullAddress, setFullAddress] = useState("")
    const [dateString, setDateString] = useState("")
    const [accountDisplayName, setAccountDisplayName] = useState("")
    const [accountId, setAccountId] = useState("")
    const [primaryColor, setPrimaryColor] = useState("")
    const [isFetchingPass, setIsFetchingPass] = useState(false)
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const encodedString = urlParams.get('t')
        function b64_to_utf8(str) {
            return decodeURIComponent(escape(window.atob(str)))
        }
        const decodedString = b64_to_utf8(encodedString)
        const ticket = JSON.parse(decodedString)
        var QRCode = require('qrcode')
        QRCode.toDataURL(ticket.ticketId).then(code => {
            setTicketName(ticket.ticketName)
            setTicketId(ticket.ticketId)
            setTicketCode(code)
            setDateString(ticket.dateString.trim())
            setEventName(ticket.eventName)
            setLocationName(ticket.locationName)
            setFullAddress(ticket.fullAddress)
            setAccountDisplayName(ticket.accountDisplayName)
            setAccountId(ticket.accountId)
            setPrimaryColor(ticket.primaryColor || "#ffffff")
        })
    },[])

    function getContrastYIQ(hexcolor){
        hexcolor = hexcolor.replace("#", "")
        var r = parseInt(hexcolor.substr(0,2),16)
        var g = parseInt(hexcolor.substr(2,2),16)
        var b = parseInt(hexcolor.substr(4,2),16)
        var yiq = ((r*299)+(g*587)+(b*114))/1000
        return (yiq >= 128) ? '#000000' : '#ffffff'
    }

    const downloadAppleWalletPass = async () => {
        try {
            setIsFetchingPass(true)
            const locationString = `${locationName ? `${locationName}, ` : ""}${fullAddress}`
            const requestBody = {
                ticket_id: ticketId,
                account_name: accountDisplayName,
                color: primaryColor,
                ...(dateString && { date_string: dateString }),
                ...(locationString && { location_string: locationString }),
            }
            const response = await fetch(`https://api.sidebarticketing.com/f/download-apple-wallet-pass`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            })
            setIsFetchingPass(false)
            const blob = await response.blob()
            const blobUrl = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = blobUrl
            a.download = `${eventName}_ticket.pkpass`
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(blobUrl)
            a.remove()
        } catch (error) {
            console.log(error)
        }
    }

    const downloadGoogleWalletPass = async () => {
        try {
            setIsFetchingPass(true)
            const locationString = `${locationName ? `${locationName}, ` : ""}${fullAddress}`
            const requestBody = {
                ticket_id: ticketId,
                account_name: accountDisplayName,
                color: primaryColor,
                ...(dateString && { date_string: dateString }),
                ...(locationString && { location_string: locationString }),
            }
            const response = await fetch(`https://api.sidebarticketing.com/f/download-google-wallet-pass`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            })
            setIsFetchingPass(false)
            const url = await response.text()
            window.location.href = url
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="flex flex-col items-center p-4">
            <div style={{ backgroundColor: primaryColor, color: getContrastYIQ(primaryColor)}} className='mb-6 md:my-12 w-full max-w-md drop-shadow-xl px-8 py-6 rounded-lg border border-neutral-200'>
                <div className='flex flex-row items-center'>
                    <AccountIcon accountId={accountId} size={150} />
                    <p>{accountDisplayName}</p>
                </div>
                <p className="text-3xl font-bold mt-6">{ticketName}</p>
                <p>{eventName}</p>
                <div className="mt-8 flex flex-col">
                    {locationName && <p>{locationName}</p>}
                    {fullAddress && <p>{fullAddress}</p>}
                    {dateString && <p>{dateString}</p>}
                </div>
                <div className="mt-8 flex flex-col items-center rounded-lg gap-2">
                    <img src={ticketCode} alt="QR Code" className="h-40 w-40 flex-none rounded-lg bg-white object-cover object-center " />
                    <p className="text-sm font-light">{ticketId}</p>
                </div>
            </div>
            <div className='flex flex-row gap-4'>
                {!isFetchingPass ?
                    <>
                        <button disabled={isFetchingPass} onClick={downloadAppleWalletPass}>
                            <img className='h-14 pb-4 box-content' src={addToAppleWalletButton} alt="Add to Apple Wallet" />
                        </button>
                        <button disabled={isFetchingPass} onClick={downloadGoogleWalletPass}>
                            <img className='h-14 pb-4 box-content' src={addToGoogleWalletButton} alt="Add to Apple Wallet" />
                        </button>
                    </>
                :
                    <div className='h-14 pb-4 box-content animate-pulse'> Generating Wallet Pass...</div>
                }
            </div>
            <footer>
                <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
                    <p className="text-center text-xs leading-5 text-neutral-500 dark:text-neutral-400">
                        &copy; 2024 Sidebar Ticketing, LLC. All rights reserved. <br /> <a href="https://sidebarticketing.com" alt="sidebar ticketing" className='underline'>Sell tickets with Stripe</a>
                    </p>
                </div>
            </footer>
        </div>
    )
}