import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, verifyBeforeUpdateEmail } from 'firebase/auth'
import { auth } from '../firebase'

export async function sendLoginLink(email) {
    const actionCodeSettings = {
        url: window.location.origin + '/finalize-signin',
        handleCodeInApp: true
    }

    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    window.localStorage.setItem('emailForSignIn', email)
}

// Complete sign in after clicking email link
export async function completeSignIn() {
    if (!isSignInWithEmailLink(auth, window.location.href)) {
        throw new Error('Invalid sign-in link. Please request a new one.')
    }

    const email = window.localStorage.getItem('emailForSignIn')
    if (!email) {
        throw new Error('Please use the same device and browser you used to request the sign-in link.')
    }

    try {
        const result = await signInWithEmailLink(auth, email, window.location.href)
        window.localStorage.removeItem('emailForSignIn')
        
        // The user document will be created/updated by a Firebase Auth trigger function
        return result.user
    } catch (error) {
        // Handle specific Firebase errors
        if (error.code === 'auth/invalid-action-code') {
            throw new Error('This sign-in link has expired or already been used. Please request a new one.')
        }
        throw error // Re-throw other errors
    }
}

// step 1 of change email: reauth with current email
export async function sendReauthenticationLink() {
    const user = auth.currentUser
    const actionCodeSettings = {
        url: `${window.location.origin}/settings`,
        handleCodeInApp: true
    }

    await sendSignInLinkToEmail(auth, user.email, actionCodeSettings)
    window.localStorage.setItem('emailForSignIn', user.email)
}

// step 2 of change email: complete the reauth sign-in with current email
export async function completeReauth() {
    if (!isSignInWithEmailLink(auth, window.location.href)) {
        throw new Error('Invalid authentication link')
    }

    const email = window.localStorage.getItem('emailForSignIn')
    if (!email) {
        throw new Error('Please use the same device and browser you used to request the authentication link.')
    }

    await signInWithEmailLink(auth, email, window.location.href)
    window.localStorage.removeItem('emailForSignIn')
}

// step 3 of change email: send verification email to new email
export async function initiateEmailUpdate(newEmail) {
    const actionCodeSettings = {
        url: window.location.origin,
    }
    
    await verifyBeforeUpdateEmail(auth.currentUser, newEmail, actionCodeSettings)
}