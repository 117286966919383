import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import stripeAppBadge from './images/stripe-app-badge-dark.png'
import paymentLinkScreenshot from './images/payment-link-screenshot.png'
import ticketsScreenshot from './images/tickets-screenshot.png'
import checkInScreenshot from './images/checkin-screenshot.png'
import { TicketIcon, LinkIcon, QrCodeIcon, CheckIcon } from '@heroicons/react/24/outline'

const features = [
    {
        name: 'Branded Pages',
        description: 'Add your logo, hero image, and accent color, while our brand stays out of the way.',
    },
    {
        name: 'Registration Forms',
        description: 'Add a form to your page with multi-select, single select, and free form questions.'
    },
    {
        name: 'Capacities',
        description: 'Set a maximum quantity available at either the ticket type level, event level, or both.',
    },
    {
        name: 'Pass on the Fee',
        description: 'Our fee is $0.79 per ticket and can be passed to the buyer for cost-free ticketing.',
    },
    {
        name: 'Bundles',
        description: 'Sell any combo of tickets at a reduced price. Works seamlessly with capacities.',
    },
    {
        name: 'Recurring Events',
        description: 'Create a repeating pattern for an event that occurs over multiple days and months.'
    },
    {
        name: 'Time Slots',
        description: 'Create up to 24 time slots in a single day for time based ticketing.'
    },
    {
        name: 'Blackout dates',
        description: 'Create custom availability for a single date that is unlike the rest.'
    },
    {
        name: 'Send Free Tickets',
        description: 'Comp a ticket to any email address right from the dashboard.',
    },
    {
        name: 'Discount Codes',
        description: 'Allow coupon codes on the checkout form, or, send a link preloaded with a discount.',
    },
    {
        name: 'Exchanges',
        description: 'Exchange tickets between types and events. Works seamlessly with capacities.',
    },
    {
        name: 'Refunds',
        description: 'Automatic ticket invalidation when the order is refunded in Stripe.',
    },
    {
        name: 'Add Sales Tax',
        description: 'Add a line on the checkout form to display tax. Either price inclusive or exclusive.',
    },
    {
        name: 'Ticket Scanning',
        description: 'Scan the QR codes on tickets with our easy to use Check-in app',
    },
    {
        name: 'Check-in by Name',
        description: 'For a more personal feel at a smaller event, simply lookup by name and redeem.',
    },
    {
        name: 'PDF Attendee List',
        description: 'Print one as a backup or as the primary method of check-in.',
    },
    {
        name: 'Apple & Google Wallet',
        description: 'The most convenient way for attendees to save their ticket.',
    },
    {
        name: 'Quick Support',
        description: 'Need something not on the list? Ask and we may be able to help!',
    },
]

const currencies = [
    {code: "USD", name: "United States Dollar", price:"$0.79"},
    {code: "CAD", name: "Canadian Dollar", price:"$1.10"},
    {code: "EUR", name: "Euro", price:"€0.79"},
    {code: "GBP", name: "British Pound", price:"£0.70"},
    {code: "AUD", name: "Australian Dollar", price:"$1.20"},
    {code: "NZD", name: "New Zealand Dollar", price:"$1.30"},
    {code: "DKK", name: "Danish Krone", price:"kr5.50"},
    {code: "INR", name: "Indian Rupee", price:"₹70"},
    {code: "RON", name: "Romanian Leu", price:"lei3.79"},
    {code: "CHF", name: "Swiss Franc", price:"CHF0.79"},
    {code: "ZAR", name: "South African Rand", price:"R18"},
    {code: "MXN", name: "Mexican Peso", price:"$15"},
    {code: "RUB", name: "Russian Ruble", price:"₽79"},
    {code: "BGN", name: "Bulgarian Lev", price:"лв1.50"},
    {code: "SGD", name: "Singapore Dollar", price:"$1.10"},
    {code: "SEK", name: "Swedish Krona", price:"kr9"},
    {code: "NOK", name: "Norwegian Krone", price:"kr9"},
    {code: "PLN", name: "Polish Zloty", price:"zł3.30"},
    {code: "AED", name: "Emirati Dirham", price:"3.00"},
]

export default function Homepage() {
    const [open, setOpen] = useState(false)

    return (
        <>
            <section className='pt-24 pb-24 lg:py-36'>
                <div className='w-10/12 max-w-4xl mx-auto'>
                    <img className="h-9 lg:h-12 mb-6 sidebar-logo" alt="sidebar-ticketing-logo" />
                    <h1 className="text-4xl tracking-tight font-bold sm:text-7xl text-stone-800 dark:text-stone-100 mx-auto">
                        Easy and affordable event registration
                    </h1>
                    <div className="text-stone-600 dark:text-stone-300 mt-6 text-lg leading-8">
                        <div className="flex flex-row gap-1 items-center flex-wrap">
                            <p>Find it on the</p>
                            <a href="https://marketplace.stripe.com/apps/sidebar-ticketing-1" className='underline'>Stripe App Marketplace</a>
                        </div>
                        <div className="flex flex-row gap-1 items-center">
                            <a href="https://buy.stripe.com/test_fZe15S2aF3fe9AQeV8" target="_blank" className='underline' rel="noreferrer">See an example</a>
                        </div>
                        <div className="mt-6">
                            <a
                                href="/signin"
                                className="inline-flex items-center rounded-md bg-teal-600 px-6 py-3 text-lg font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                            >
                                Get Started
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-24 flex flex-col bg-teal-100 dark:bg-teal-950">
                <div className='w-10/12 max-w-4xl mx-auto'>
                    <div className="flex flex-col md:flex-row">
                        <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-stone-800 dark:bg-stone-900 mr-6 mb-6 md:mb-0">
                            <LinkIcon className="h-8 w-8 text-white" aria-hidden="true" />
                        </span>
                        <div >
                            <h2 className="text-3xl font-bold tracking-tight text-stone-800 dark:text-stone-100">Use the best checkout page on the web</h2>
                            <p className="text-lg text-stone-600 dark:text-stone-300 mt-1">
                                Rely on Stripe Checkout for your event
                            </p>
                        </div>
                    </div>
                </div>
                <img
                    className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 mt-12 max-w-4xl mx-auto w-11/12"
                    src={paymentLinkScreenshot}
                    alt="Payment link screenshot"
                />
            </section>
            <section className="py-24 flex flex-col">
                <div className='w-10/12 max-w-4xl mx-auto'>
                    <div className="flex flex-col md:flex-row">
                        <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-stone-800 dark:bg-teal-950 mr-6 mb-6 md:mb-0">
                            <TicketIcon className="h-8 w-8 text-white dark:text-stone-100" aria-hidden="true" />
                        </span>
                        <div >
                            <h2 className="text-3xl font-bold tracking-tight text-stone-800 dark:text-stone-100">We add a perfectly paired ticketing experience</h2>
                            <p className="text-lg text-stone-600 dark:text-stone-300 mt-1">
                                Impress your buyers with a branded post purchase page and email
                            </p>
                        </div>
                    </div>
                </div>
                <img
                    className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5  mt-12 max-w-4xl mx-auto w-11/12"
                    src={ticketsScreenshot}
                    alt="Payment link screenshot"
                />
            </section>
            <section className="py-24 flex flex-col bg-teal-100 dark:bg-teal-950">
                <div className='w-10/12 max-w-4xl mx-auto'>
                    <div className="flex flex-col md:flex-row">
                        <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-stone-800 dark:bg-stone-900 mr-6 mb-6 md:mb-0">
                            <QrCodeIcon className="h-8 w-8 text-white" aria-hidden="true" />
                        </span>
                        <div >
                            <h2 className="text-3xl font-bold tracking-tight text-stone-800 dark:text-stone-100">Use our app for easy event check-in</h2>
                            <p className="text-lg text-stone-600 dark:text-stone-300 mt-1">
                                Scan tickets or check people in&nbsp;by&nbsp;name
                            </p>
                        </div>
                    </div>
                </div>
                <img
                    className="mt-12 max-w-4xl mx-auto w-11/12"
                    src={checkInScreenshot}
                    alt="Payment link screenshot"
                />
            </section>
            <section>
                <div className="py-24 sm:py-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-teal-600">The features you need</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">Ticketing that comes to&nbsp;you</p>
                            <p className="mt-6 text-base leading-7 text-stone-600 dark:text-stone-300">
                            We bring a powerful suite of ticketing and registration features right into your Stripe Dashboard.
                            </p>
                        </div>
                        <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 sm:grid-cols-2 lg:gap-y-16">
                            {features.map((feature) => (
                            <div key={feature.name} className="relative pl-9">
                                <dt className="font-semibold">
                                <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-teal-500" aria-hidden="true" />
                                {feature.name}
                                </dt>
                                <dd className="mt-2 text-stone-600 dark:text-stone-300">{feature.description}</dd>
                            </div>
                            ))}
                        </dl>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative pt-20 sm:pt-28 px-6 bg-teal-100 dark:bg-teal-950">
                <div className="relative">
                    <div className="mx-auto max-w-lg sm:text-center">
                        <h2 className="text-3xl font-bold tracking-tight sm:text-5xl text-center">
                            $0.79 per ticket
                        </h2>
                        <p className="mt-1 text-small">In USD. <button className="underline cursor-pointer" onClick={() => setOpen(true)}>View in other currencies.</button></p>
                            <Transition.Root show={open} as={Fragment}>
                                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-stone-500 bg-opacity-75 transition-opacity" />
                                    </Transition.Child>
                                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-black p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">                                     
                                                <div className="text-center">
                                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                                                        Per ticket pricing
                                                    </Dialog.Title>
                                                </div>
                                                <ul className="divide-y divide-stone-100 dark:divide-stone-800 mt-2">
                                                    {currencies.map((currency) => (
                                                        <li key={currency.code} className="flex justify-between py-2">
                                                            <div className="flex gap-1 items-center">
                                                                <p className="text-sm font-semibold">{currency.code}</p>
                                                                <p className="truncate text-xs text-stone-600 dark:text-stone-300">{currency.name}</p>
                                                            </div>
                                                            <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                                                                <p className="text-sm">{currency.price}</p>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p className='mt-2 text-xs text-stone-600 dark:text-stone-300'>Your currency missing? If you can use it in Stripe, you can use it in Sidebar Ticketing. You'll be charged at a reasonable conversion from USD.</p>
                                                <div className="mt-5 sm:mt-6">
                                                    <button
                                                        type="button"
                                                        className="inline-flex w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        Dismiss
                                                    </button>
                                                </div>
                                            </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                        <p className="mt-6 text-lg max-w-lg text-center mx-auto">Pass this super low fee to the buyer<br />for cost-free ticketing!</p>
                        <p className="mt-4 text-lg max-w-lg text-center mx-auto">
                            Add the Sidebar Ticketing App from your Stripe Dashboard. If you don't have Stripe, you can sign up for free and start selling in minutes at <a className="underline" href="https://stripe.com">stripe.com</a>.
                        </p>
                        <div className="mt-8 flex justify-center">
                            <a href='https://marketplace.stripe.com/apps/sidebar-ticketing-1'><img src={stripeAppBadge} alt="stripe app button" className='h-16' /></a>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="pb-20 bg-teal-100 dark:bg-teal-950">
                <div className="max-w-7xl mx-auto pt-12 flex flex-col items-center" aria-label="Footer">
                    <p>Get in touch → hello@sidebarticketing.com</p>
                    <div className='flex space-x-2 flex-col lg:flex-row items-center'>
                        <p className='flex'>&copy; Sidebar Ticketing, LLC</p>
                        <a className='underline' href='/terms'>Terms of Service</a>
                        <a className='underline' href='/privacy'>Privacy Policy</a>
                    </div>
                </div>
            </footer>
        </>
    )
}