/* eslint-disable jsx-a11y/no-redundant-roles */
import EventCard from '../components/EventCard'
import { useState, useEffect } from 'react'
import { collection, query, where, orderBy, limit, onSnapshot } from 'firebase/firestore'
import { Link } from 'react-router-dom'

export default function Events({events, db, uid, functions}) {
    const [showArchived, setShowArchived] = useState(false)
    const [archivedEvents, setArchivedEvents] = useState([])

    // Only fetch archived events when needed
    useEffect(() => {
        if (!showArchived) return

        const q = query(collection(db, "events"),
            where("accountId", "==", uid),
            where("archived", "==", true),
            orderBy("lastModified", "desc"),
            limit(250)
        )

        const unsubscribe = onSnapshot(q,
            (querySnapshot) => {
                setArchivedEvents(querySnapshot.docs.map(doc => ({
                    paymentLinkId: doc.id,
                    ...doc.data()
                })))
            },
            (error) => {
                console.log(error)
            }
        )

        return () => unsubscribe()
    }, [db, uid, showArchived])

    const displayedEvents = (showArchived ? archivedEvents : events)

    return (
        <div className="space-y-6">
            <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4">
                <h1 className="text-3xl font-bold">{showArchived ? 'Archived' : 'Events'}</h1>
                <div className="flex items-center gap-x-2">
                    <button
                        onClick={() => setShowArchived(!showArchived)}
                        className="font-semibold text-teal-700 hover:opacity-90 shadow-sm border border-teal-700 rounded-md px-4 py-2"
                    >
                        {showArchived ? 'Back to active events' : 'Show archived'}
                    </button>
                    <Link
                        to="/events/new"
                        className="rounded-md bg-teal-700 px-4 py-2 font-semibold text-white shadow-sm border border-teal-700 hover:bg-teal-600"
                    >
                        Create event
                    </Link>
                </div>
            </div>
            <div className="relative">
                <ul role="list">
                    {displayedEvents.map((event) => (
                        <EventCard key={event.id} event={event} functions={functions} events={events} db={db} />
                    ))}
                </ul>
            </div>
        </div>
    )
}
  