/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import { useEffect } from 'react'
import EventMenu from './EventMenu'

export default function EventCard({ event, functions, events, db }) {
    const navigate = useNavigate()

    useEffect(() => {
        function handleClickOutside(event) {
            const dropdown = document.getElementById(`eventActions-${event.paymentLinkId}`);
            if (dropdown && !event.target.closest(`button[data-dropdown="${event.paymentLinkId}"]`)) {
                dropdown.classList.add('hidden');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [event.paymentLinkId]);

    return (
        <li className="flex hover:bg-stone-50 dark:hover:bg-stone-900 cursor-pointer border-t border-stone-200 dark:border-stone-800">
            <div 
                onClick={() => navigate(`/events/${event.paymentLinkId}`)}
                className="flex-1 flex items-center gap-x-4 py-4 px-2"
            >
                <div className="flex-shrink-0">
                    {event.eventImageUrl ? (
                        <img
                            src={event.eventImageUrl}
                            alt={event.eventName}
                            className="h-16 w-16 rounded object-cover"
                        />
                    ) : (
                        <div className="h-16 w-16 rounded bg-stone-200 dark:bg-stone-700 flex items-center justify-center">
                            <span className="text-xl font-semibold text-stone-500 dark:text-stone-400">
                                {event.eventName.charAt(0)}
                            </span>
                        </div>
                    )}
                </div>
                <div className="flex flex-1 min-w-0 justify-between items-start">
                    <div className="space-y-1">
                        <div className="flex items-center gap-x-2">
                            <span className="font-semibold">{event.eventName}</span>
                            {!event.livemode && (
                                <span className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                                    test
                                </span>
                            )}
                        </div>
                        <div className="text-stone-500 dark:text-stone-400">
                            {event.issued ? `${event.issued} sold` : 'No sales'}
                            {event.mostRecentTicketSold && (
                                <span>
                                    {' '}(last {formatDistanceToNow(event.mostRecentTicketSold.toDate(), { addSuffix: true })})
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="text-right hidden md:block">
                        <div className="font-medium">
                            {Object.values(event.ticketTypes || {}).filter(type => type.visible).length} ticket type{Object.values(event.ticketTypes || {}).filter(type => type.visible).length > 1 ? 's' : ''}
                        </div>
                        {event.isTicketingLive ? (
                            <div className="mt-1 flex items-center justify-end gap-x-1.5">
                                <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                    <div className="size-1.5 rounded-full bg-emerald-500" />
                                </div>
                                <p className="text-stone-500 dark:text-stone-400">Sales live</p>
                            </div>
                        ) : (
                            <div className="mt-1 flex items-center justify-end gap-x-1.5">
                                <div className="flex-none rounded-full bg-stone-500/20 p-1">
                                    <div className="size-1.5 rounded-full bg-stone-500" />
                                </div>
                                <p className="text-stone-500 dark:text-stone-400">Not live</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div 
                className="flex items-center px-4 hover:bg-stone-50 dark:hover:bg-stone-900 cursor-pointer"
                onClick={(e) => {
                    e.stopPropagation()
                    document.getElementById(`eventActions-${event.paymentLinkId}`).classList.toggle('hidden')
                }}
                data-dropdown={`eventActions-${event.paymentLinkId}`}
            >
                <EventMenu 
                    key={event.paymentLinkId}
                    eventId={event.paymentLinkId}
                    menuId={`eventActions-${event.paymentLinkId}`}
                    issued={event.issued || 0}
                    customTrigger={
                        <div className="text-stone-500 dark:text-stone-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                            </svg>
                        </div>
                    }
                    functions={functions}
                    events={events}
                    db={db}
                />
            </div>
        </li>
    )
} 