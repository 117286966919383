import { useState, useEffect, useRef, useCallback } from "react";
import {
    collection,
    query,
    where,
    limit,
    getDocs,
    orderBy,
    startAfter,
} from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { httpsCallable } from 'firebase/functions';

export default function Transactions({ uid, db, events, functions }) {
    const [orders, setOrders] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const ITEMS_PER_PAGE = 50;
    const navigate = useNavigate();
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedEvent, setSelectedEvent] = useState(() => {
        const params = new URLSearchParams(location.search);
        return params.get('event') || "";
    });

    const handleSearch = async (searchValue) => {
        setSearchTerm(searchValue)
        setIsLoading(true)

        try {
            if (searchValue.trim()) {
                const searchOrders = httpsCallable(functions, 'searchOrdersByBuyerNameOrEmail');
                const result = await searchOrders({
                    searchTerm: searchValue,
                });
                setOrders(result.data)
                setHasMore(false) // Disable pagination during search
            } else {
                // If search is cleared, fetch normal paginated results
                fetchOrders()
            }
        } catch (error) {
            console.error('Search error:', error)
        }

        setIsLoading(false)
    }

    const fetchOrders = useCallback(async (isNextPage = false) => {
        setIsLoading(true);
        let queryConstraints = [
            where("accountId", "==", uid),
            orderBy("orderDate", "desc"),
            limit(ITEMS_PER_PAGE)
        ];

        if (selectedEvent) {
            queryConstraints.push(where("paymentLinkId", "==", selectedEvent));
        }

        let q = query(
            collection(db, "orders"),
            ...queryConstraints
        );

        if (isNextPage && lastDoc) {
            q = query(
                collection(db, "orders"),
                ...queryConstraints,
                startAfter(lastDoc)
            );
        }

        try {
            const querySnapshot = await getDocs(q);
            const docs = querySnapshot.docs;

            setLastDoc(docs[docs.length - 1]);
            setHasMore(docs.length === ITEMS_PER_PAGE);
            setOrders(docs.map((doc) => ({ ...doc.data() })));
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }, [db, uid, selectedEvent, lastDoc, ITEMS_PER_PAGE]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    useEffect(() => {
        setPage(1);
        setLastDoc(null);
        fetchOrders();
    }, [fetchOrders, selectedEvent]);

    const handleNextPage = () => {
        setPage(prev => prev + 1);
        fetchOrders(true);
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(prev => prev - 1);
            fetchOrders();
        }
    };

    const debounceTimeout = useRef(null);
    const handleSearchInput = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            handleSearch(value);
        }, 300);
    };

    return (
        <div className="space-y-6">
            <div className="flex flex-col sm:flex-row sm:items-baseline sm:justify-between gap-4">
                <h1 className="text-3xl font-bold">Orders</h1>
                <div className="flex flex-col sm:flex-row gap-4 mt-2 md:mt-0">
                    <select
                        value={selectedEvent}
                        onChange={(e) => setSelectedEvent(e.target.value)}
                        className="rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 text-sm bg-white dark:bg-stone-900"
                    >
                        <option value="">All Events</option>
                        {events.map((event) => (
                            <option key={event.paymentLinkId} value={event.paymentLinkId}>
                                {event.eventName}
                            </option>
                        ))}
                    </select>
                    <input
                        type="text"
                        placeholder="Search by name or email..."
                        value={searchTerm}
                        onChange={handleSearchInput}
                        className="flex-1 rounded-md border-stone-300 dark:border-stone-700 focus:border-teal-500 focus:ring-teal-500 sm:text-sm bg-white dark:bg-stone-900"
                    />
                </div>
            </div>
            {orders.length === 0 ? (
                <div className="text-center py-12">
                    <div className="text-stone-500 dark:text-stone-400">
                        <svg className="mx-auto h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                        </svg>
                        <h3 className="mt-4 text-lg font-medium">No orders found</h3>
                        <p className="mt-2 text-sm">
                            {searchTerm 
                                ? "Try adjusting your search terms or filters"
                                : "Orders will appear here once you receive your first order"}
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    <div className="block sm:hidden">
                        {orders.map((order) => (
                            <div 
                                key={order.paymentIntentId}
                                onClick={() => navigate(`/orders/${order.paymentIntentId}`, { state: { order } })}
                                className="mb-2 p-4 border rounded-lg border-stone-200 dark:border-stone-700 hover:bg-stone-50 dark:hover:bg-stone-900 cursor-pointer"
                            >
                                <div className="flex justify-between items-start mb-2">
                                    <div className="font-medium">{order.buyerName}</div>
                                    <div className="text-sm">
                                        {new Intl.NumberFormat(undefined, {
                                            style: 'currency',
                                            currency: order.currency || 'USD'
                                        }).format((order.orderTotal || 0) / 100)}
                                    </div>
                                </div>
                                <div className="text-sm text-stone-500">{order.buyerEmail}</div>
                                <div className="text-sm text-stone-500">
                                    {events.find(event => event.paymentLinkId === order.paymentLinkId)?.eventName || 'Unknown Event'}
                                </div>
                                <div className="text-sm text-stone-500">
                                    {order.orderDate._seconds
                                        ? new Date(order.orderDate._seconds * 1000).toLocaleString()
                                        : order.orderDate.toDate().toLocaleString()}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="hidden sm:block overflow-x-auto">
                        <table className="min-w-full divide-y divide-stone-200 dark:divide-stone-700">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap p-2 text-left text-sm font-semibold"
                                    >
                                        Buyer
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap p-2 text-left text-sm font-semibold"
                                    >
                                        Email
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap p-2 text-left text-sm font-semibold"
                                    >
                                        Total
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap p-2 text-left text-sm font-semibold"
                                    >
                                        Event
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap p-2 text-left text-sm font-semibold"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="relative whitespace-nowrap sm:pr-0"
                                    >
                                        <span className="sr-only">View</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-stone-200 dark:divide-stone-700">
                                {orders.map((order) => (
                                    <tr
                                        key={order.paymentIntentId}
                                        onClick={() => navigate(`/orders/${order.paymentIntentId}`, { state: { order } })}
                                        className="cursor-pointer hover:bg-stone-50 dark:hover:bg-stone-900"
                                    >
                                        <td className="whitespace-nowrap p-2 text-sm truncate max-w-[200px]">
                                            {order.buyerName}
                                        </td>
                                        <td className="whitespace-nowrap p-2 text-sm truncate max-w-[200px]">
                                            {order.buyerEmail}
                                        </td>
                                        <td className="whitespace-nowrap p-2 text-sm">
                                            {new Intl.NumberFormat(undefined, {
                                                style: 'currency',
                                                currency: order.currency || 'USD'
                                            }).format((order.orderTotal || 0) / 100)}
                                        </td>
                                        <td className="whitespace-nowrap p-2 text-sm">
                                            {events.find(event => event.paymentLinkId === order.paymentLinkId)?.eventName || 'Unknown Event'}
                                        </td>
                                        <td className="whitespace-nowrap p-2 text-sm">
                                            {order.orderDate._seconds
                                                ? new Date(order.orderDate._seconds * 1000).toLocaleString()
                                                : order.orderDate.toDate().toLocaleString()}
                                        </td>
                                        <td className="relative whitespace-nowrap p-2 text-right text-sm font-medium">
                                            <span className="text-teal-600 hover:text-teal-900">
                                                View
                                                <span className="sr-only">
                                                    , {order.paymentIntentId}
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            {!searchTerm && orders.length > 0 && (
                <div className="flex items-center justify-between">
                    <div className="flex flex-1 justify-between sm:hidden">
                        <button
                            onClick={handlePrevPage}
                            disabled={page === 1 || isLoading}
                            className="relative inline-flex items-center rounded-l-md border border-stone-300 dark:border-stone-600 px-4 py-2 text-sm font-medium hover:bg-stone-50 dark:hover:bg-stone-900 disabled:opacity-50"
                        >
                            Previous
                        </button>
                        <button
                            onClick={handleNextPage}
                            disabled={!hasMore || isLoading}
                            className="relative inline-flex items-center rounded-r-md border border-stone-300 dark:border-stone-600 px-4 py-2 text-sm font-medium hover:bg-stone-50 dark:hover:bg-stone-900 disabled:opacity-50"
                        >
                            Next
                        </button>
                    </div>
                    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                            <p className="text-sm">
                                Showing{' '}
                                <span className="font-medium">
                                    {((page - 1) * ITEMS_PER_PAGE) + 1}-{Math.min(page * ITEMS_PER_PAGE, (page - 1) * ITEMS_PER_PAGE + orders.length)}
                                </span>
                            </p>
                        </div>
                        <div>
                            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                <button
                                    onClick={handlePrevPage}
                                    disabled={page === 1 || isLoading}
                                    className="relative inline-flex items-center rounded-l-md border border-stone-300 dark:border-stone-600 px-4 py-2 text-sm font-medium hover:bg-stone-50 dark:hover:bg-stone-900 disabled:opacity-50"
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={handleNextPage}
                                    disabled={!hasMore || isLoading}
                                    className="relative inline-flex items-center rounded-r-md border border-stone-300 dark:border-stone-600 px-4 py-2 text-sm font-medium hover:bg-stone-50 dark:hover:bg-stone-900 disabled:opacity-50"
                                >
                                    Next
                                </button>
                            </nav>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
