import { useState, useEffect, useRef, useCallback } from 'react'
import { collection, query, where, limit, orderBy, onSnapshot } from "firebase/firestore"
import QRCode from 'qrcode'
import { useParams, Link } from 'react-router-dom'
import Ticket from '../components/Ticket'
import { httpsCallable } from 'firebase/functions'
import DownloadAttendees from '../components/DownloadAttendees'
import { ArrowDownTrayIcon, CameraIcon, FunnelIcon } from '@heroicons/react/24/outline'
import { FunnelIcon as FunnelIconSolid } from '@heroicons/react/24/solid'
import { Dialog } from '@headlessui/react'

export default function Registrations({ uid, db, events, functions, auth, accountDisplayName, accountPrimaryColor }) {
    const { eventId } = useParams()
    const [tickets, setTickets] = useState([])
    const [now, setNow] = useState(new Date())
    const [searchTerm, setSearchTerm] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const debounceTimeout = useRef(null)
    const [showCustomFields, setShowCustomFields] = useState(true)
    const [allTickets, setAllTickets] = useState([])
    const dropdownRef = useRef(null)
    const [isDownloading, setIsDownloading] = useState(false)
    const [qrCodeImage, setQrCodeImage] = useState(null)
    const [isLoadingQr, setIsLoadingQr] = useState(false)
    const [loginLink, setLoginLink] = useState()
    const [copiedState, setCopiedState] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const getDateDisplayString = (ticket) => {
        if (ticket.eventStartDate === "") {
            return ""
        }
        const startDateTime = new Date(`${ticket.eventStartDate}T${ticket.eventStartTime || "00:00"}`)
        const endDateTime = new Date(`${ticket.eventEndDate}T${ticket.eventEndTime || "00:00"}`)
        
        const startDateString = startDateTime.toDateString()
        const parts = startDateString.split(' ')
        const day = parseInt(parts[2], 10)
        const startDateStringFormatted = `${parts[1]} ${day}, ${parts[3]}`
        
        if ((startDateTime.toDateString() === endDateTime.toDateString()) || ticket.eventStartDate === undefined) {
          return startDateStringFormatted
        } else {
          let hour = startDateTime.getHours()
          const minute = String(startDateTime.getMinutes()).padStart(2, '0')
          const ampm = hour >= 12 ? 'PM' : 'AM'
          if (hour > 12) {
              hour -= 12
          } else if (hour === 0) {
              hour = 12
          }
          const timeString = `${hour}:${minute} ${ampm}`
          return `${startDateStringFormatted}, ${timeString}`
        }
    }
    
    const getTimeDisplayString = (ticket) => {
        if (ticket.eventEndDate === "") {
            return ""
        }
        const startDateTime = new Date(`${ticket.eventStartDate}T${ticket.eventStartTime || "00:00"}`)
        const endDateTime = new Date(`${ticket.eventEndDate}T${ticket.eventEndTime || "00:00"}`)
        
        const endDateString = endDateTime.toDateString()
        const partsEnd = endDateString.split(' ')
        const dayEnd = parseInt(partsEnd[2], 10)
        const endDateStringFormatted = `${partsEnd[1]} ${dayEnd}, ${partsEnd[3]}`
        
        let hourStart = startDateTime.getHours()
        const minuteStart = String(startDateTime.getMinutes()).padStart(2, '0')
        const ampmStart = hourStart >= 12 ? 'PM' : 'AM'
        if (hourStart > 12) {
            hourStart -= 12
        } else if (hourStart === 0) {
            hourStart = 12
        }
        const startTimeStringFormatted = `${hourStart}:${minuteStart} ${ampmStart}`
        
        let hourEnd = endDateTime.getHours()
        const minuteEnd = String(endDateTime.getMinutes()).padStart(2, '0')
        const ampmEnd = hourEnd >= 12 ? 'PM' : 'AM'
        if (hourEnd > 12) {
            hourEnd -= 12
        } else if (hourEnd === 0) {
            hourEnd = 12
        }
        const endTimeStringFormatted = `${hourEnd}:${minuteEnd} ${ampmEnd}`
        
        if ((startDateTime.toDateString() === endDateTime.toDateString()) && ticket.eventStartTime && ticket.eventEndTime) {
          return `${startTimeStringFormatted} - ${endTimeStringFormatted}`
        } else if (startDateTime.toDateString() === endDateTime.toDateString()) {
          return ""
        } else {
          return `${endDateStringFormatted}, ${endTimeStringFormatted}`
        }
    }

    const fetchTickets = useCallback(async () => {
        setIsLoading(true)
        let queryConditions = [
            where("accountId", "==", uid),
            where("paymentLinkId", "==", eventId),
            orderBy("buyerName"),
            limit(501)
        ]

        let q = query(collection(db, "tickets"), ...queryConditions)

        return onSnapshot(q, async (snapshot) => {
            const docs = snapshot.docs;
            
            const ticketsPromises = docs.map(async (doc) => {
                const t = doc.data()
                let slotStart, slotEnd
                if (t.selectedDate) {
                    let startTime = '00:00:00'
                    let endTime = '00:00:00'
                    if (t.selectedTimeSlot) {
                        const timeParts = t.selectedTimeSlot.split(" - ")
                        if (timeParts[0].includes("am")) {
                            const parts = timeParts[0].trim().slice(0, -2).trim().split(":")
                            startTime = parts[0].padStart(2, '0') + ":" + (parts[1] || "00") + ":00"
                        } else if (timeParts[0].includes("pm")) {
                            const parts = timeParts[0].trim().slice(0, -2).trim().split(":")
                            const newPart0 = parseInt(parts[0]) + 12
                            startTime = newPart0 + ":" + (parts[1] || "00") + ":00"
                        }
                        if (timeParts[1] && timeParts[1].includes("am")) {
                            const parts = timeParts[1].trim().slice(0, -2).trim().split(":")
                            endTime = parts[0].padStart(2, '0') + ":" + (parts[1] || "00") + ":00"
                        } else if (timeParts[1] && timeParts[1].includes("pm")) {
                            const parts = timeParts[1].trim().slice(0, -2).trim().split(":")
                            const newPart0 = parseInt(parts[0]) + 12
                            endTime = newPart0 + ":" + (parts[1] || "00") + ":00"
                        }
                    }
                    slotStart = new Date(`${t.selectedDate}T${startTime}`)
                    slotEnd = new Date(`${t.selectedDate}T${endTime}`)
                }
                const uri = await QRCode.toDataURL(doc.id)
                let dateString = `${getDateDisplayString(t)} ${getTimeDisplayString(t)}`.trim()
                if (t.selectedDate) {
                    const date = new Date(t.selectedDate)
                    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
                    dateString = `${formattedDate}${t.selectedTimeSlot && ` ${t.selectedTimeSlot}`}`
                }
                const obj = {
                    ticketName: t.description,
                    ticketId: t.ticketId,
                    dateString: dateString,
                    eventName: t.eventName,
                    locationName: t.eventLocationName,
                    fullAddress: t.eventFullAddress,
                    accountDisplayName: accountDisplayName,
                    accountId: uid,
                    primaryColor: accountPrimaryColor
                }
                const jsonString = JSON.stringify(obj)
                const base64Encoded = btoa(unescape(encodeURIComponent(jsonString)))
                const url = `https://sidebarticketing.com/ticket?t=${base64Encoded}`
                return {
                    ...doc.data(),
                    qrcode: uri,
                    ...(slotStart && { slotStart }),
                    ...(slotEnd && { slotEnd }),
                    url: url
                }
            })

            const processedTickets = await Promise.all(ticketsPromises)
            setAllTickets(processedTickets)
            setTickets(processedTickets)
            setIsLoading(false)
        });
    }, [uid, eventId, db, accountDisplayName, accountPrimaryColor])

    useEffect(() => {
        const unsubscribe = fetchTickets()
        
        return () => {
            if (typeof unsubscribe === 'function') {
                unsubscribe()
            }
        }
    }, [fetchTickets])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setNow(new Date())
        }, 1000 * 10)
        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                const downloadDropdown = document.getElementById('downloadDropdown')
                if (!downloadDropdown.classList.contains('hidden')) {
                    downloadDropdown.classList.add('hidden')
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleSearch = async (searchValue) => {
        setSearchTerm(searchValue)
        setIsLoading(true)

        try {
            if (searchValue.trim()) {
                // If we have all tickets locally, filter them
                if (allTickets.length > 0) {
                    const searchLower = searchValue.toLowerCase()
                    const filtered = allTickets.filter(ticket => 
                        ticket.buyerName?.toLowerCase().includes(searchLower) ||
                        ticket.buyerEmail?.toLowerCase().includes(searchLower) ||
                        ticket.ticketId?.toLowerCase().includes(searchLower)
                    )
                    setTickets(filtered)
                } else {
                    // Otherwise use the server search
                    const searchTickets = httpsCallable(functions, 'searchTicketsByBuyerNameOrEmailOrTicketId');
                    const result = await searchTickets({
                        searchTerm: searchValue,
                        eventId: eventId
                    })
                    setTickets(result.data)
                }
            } else {
                // If search is cleared, show all tickets if we have them locally
                setTickets(allTickets.length > 0 ? allTickets : [])
                if (allTickets.length === 0) {
                    fetchTickets()
                }
            }
        } catch (error) {
            console.error('Search error:', error)
        }

        setIsLoading(false)
    }

    const handleSearchInput = (e) => {
        const value = e.target.value
        setSearchTerm(value)

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current)
        }

        debounceTimeout.current = setTimeout(() => {
            // Only search if the term is empty (to reset) or meets certain criteria
            if (!value.trim() || 
                value.includes('@') || 
                value.includes(' ') || 
                value.trim().length > 3) {
                handleSearch(value)
            }
        }, 300)
    }

    function generateLoginCode() {
        setIsLoadingQr(true)
        const getLoginToken = httpsCallable(functions, 'getLoginToken')
        getLoginToken().then(result => {
            if (result.data !== "error") {
                const encodedString = window.btoa(encodeURIComponent(result.data))
                const url = `${window.location.origin}?t=${encodedString}`
                setLoginLink(url)
                QRCode.toDataURL(url).then(code => {
                    setQrCodeImage(code)
                })
            }
        }).then(() => {
            setIsLoadingQr(false)
        }).catch(error => {
            setIsLoadingQr(false)
            console.log(error)
        })
    }

    return (
        <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:items-baseline sm:justify-between gap-2 md:gap-4">
                <h1 className="text-3xl font-bold">
                    Registration
                </h1>
                <div className="flex gap-2 items-center">
                    <div className="flex items-center gap-2">
                        {tickets.some(ticket => ticket.customFields?.length > 0) && (
                            <div className="hidden sm:flex items-center">
                                <input
                                    type="checkbox"
                                    id="showAllFields"
                                    checked={showCustomFields}
                                    onChange={(e) => setShowCustomFields(e.target.checked)}
                                    className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-stone-300 rounded"
                                />
                                <label
                                    htmlFor="showAllFields"
                                    className="mx-2 text-sm"
                                >
                                    All fields
                                </label>
                            </div>
                        )}
                    </div>
                    <button
                        type="button"
                        onClick={() => {
                            setIsDialogOpen(true)
                            generateLoginCode()
                        }}
                        className="hidden sm:inline-flex items-center gap-x-2 rounded-md bg-white dark:bg-stone-900 px-3 py-2 text-sm font-medium shadow-sm ring-1 ring-inset ring-stone-300 dark:ring-stone-700 hover:bg-stone-50 dark:hover:bg-stone-800"
                    >
                        <CameraIcon className="h-5 w-5 text-stone-400" />
                        Open on mobile
                    </button>
                    <div className="relative">
                        <button
                            type="button"
                            className="hidden sm:inline-flex items-center gap-x-2 rounded-md bg-white dark:bg-stone-900 px-3 py-2 text-sm font-medium shadow-sm ring-1 ring-inset ring-stone-300 dark:ring-stone-700 hover:bg-stone-50 dark:hover:bg-stone-800"
                            disabled={isDownloading}
                            onClick={() => document.getElementById('downloadMenu').classList.toggle('hidden')}
                        >
                            {isDownloading ? (
                                <>
                                    <svg className="animate-spin h-5 w-5 text-stone-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span>Downloading...</span>
                                </>
                            ) : (
                                <>
                                    <ArrowDownTrayIcon className="h-5 w-5 text-stone-400" />
                                    Download
                                </>
                            )}
                        </button>
                        <div
                            id="downloadMenu"
                            className="hidden absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <DownloadAttendees
                                auth={auth}
                                uid={uid}
                                eventId={eventId}
                                showCustomFields={showCustomFields}
                                issued={tickets.length}
                                onDownloadStart={() => {
                                    setIsDownloading(true);
                                    document.getElementById('downloadMenu').classList.add('hidden');
                                }}
                                onDownloadComplete={() => setIsDownloading(false)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex gap-2 items-center">
                <input
                    type="text"
                    placeholder="Search by name or email..."
                    value={searchTerm}
                    onChange={handleSearchInput}
                    className="h-11 flex-1 rounded-md border-stone-300 dark:border-stone-700 focus:border-teal-500 focus:ring-teal-500 sm:text-sm bg-white dark:bg-stone-900"
                />
                {tickets.some(ticket => ticket.customFields?.length > 0) && (
                    <button
                        onClick={() => setShowCustomFields(!showCustomFields)}
                        className="sm:hidden h-11 w-11 inline-flex items-center justify-center rounded-md bg-white dark:bg-stone-900 text-sm font-medium shadow-sm border border-stone-300 dark:border-stone-800 hover:bg-stone-50 dark:hover:bg-stone-800"
                    >
                        {showCustomFields ? (
                            <FunnelIconSolid className="h-5 w-5 text-stone-600 dark:text-stone-400" />
                        ) : (
                            <FunnelIcon className="h-5 w-5 text-stone-400" />
                        )}
                        <span className="sr-only">Toggle all fields</span>
                    </button>
                )}
                <Link
                    to={`/events/${eventId}/scan`}
                    className="sm:hidden h-11 w-11 inline-flex items-center justify-center rounded-md bg-white dark:bg-stone-900 text-sm font-medium shadow-sm border border-stone-300 dark:border-stone-800 hover:bg-stone-50 dark:hover:bg-stone-800"
                >
                    <CameraIcon className="h-5 w-5 text-stone-400" />
                    <span className="sr-only">Scan tickets</span>
                </Link>
            </div>

            <ul>
                {tickets.map(t => (
                    <Ticket
                        key={t.ticketId}
                        ticket={t}
                        now={now}
                        db={db}
                        showCustomFields={showCustomFields}
                        events={events}
                        functions={functions}
                    />
                ))}
                {tickets.length === 0 && (
                    <div className="flex items-center justify-center h-[50vh]">
                        <p className="text-neutral-600 dark:text-neutral-300 text-center">{isLoading ? 'Loading...' : 'No tickets found'}</p>
                    </div>
                )}
            </ul>

            <Dialog 
                as="div" 
                className="relative z-50" 
                open={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false)
                    setQrCodeImage(null)
                }}
            >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-4 w-full">
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <h3 className="text-lg font-medium">Scan with Mobile Device</h3>
                                <button 
                                    onClick={() => {
                                        setIsDialogOpen(false)
                                        setQrCodeImage(null)
                                    }}
                                    className="text-stone-400 hover:text-stone-500"
                                >
                                    <span className="sr-only">Close</span>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            
                            {isLoadingQr ? (
                                <div className="text-center py-2 text-sm text-stone-500">Generating QR code...</div>
                            ) : qrCodeImage && (
                                <div className="bg-stone-50 border border-stone-300 rounded p-4 space-y-3">
                                    <img src={qrCodeImage} alt="login QR code" className="w-full mx-auto"/>
                                    <div className="flex items-center justify-between text-sm">
                                        <button 
                                            className="text-teal-600 hover:text-teal-800"
                                            onClick={() => {
                                                navigator.clipboard.writeText(loginLink)
                                                setCopiedState(true)
                                                setTimeout(() => setCopiedState(false), 2000)
                                            }}
                                        >
                                            {copiedState ? "Copied!" : "Copy link"}
                                        </button>
                                        <button className="text-teal-600 hover:text-teal-800" onClick={generateLoginCode}>
                                            Generate new code
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </div>
    )
}