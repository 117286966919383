import { useFieldArray, useWatch } from 'react-hook-form'
import { PlusIcon } from '@heroicons/react/24/outline'

export default function CustomFields({ control, register, errors, setValue }) {
    const { fields: customFields, append: appendCustomField, remove: removeCustomField } = useFieldArray({
        control,
        name: "customFields"
    })
    
    const fieldTypes = useWatch({
        control,
        name: "customFields",
        defaultValue: []
    })

    const handleTypeChange = (index, value) => {
        if (value !== 'radio' && value !== 'checkbox') {
            setValue(`customFields.${index}.options`, '')
        }
    }

    return (
        <div className="space-y-4">
            <div className="space-y-4">
                {customFields.map((field, index) => (
                    <div key={field.id} className="border rounded-md p-4 bg-stone-50 dark:bg-stone-800 space-y-4 divide-y divide-stone-200 dark:divide-stone-700 dark:border-stone-700">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div>
                                <label>Question</label>
                                <input
                                    type="text"
                                    {...register(`customFields.${index}.label`)}
                                    className={`mt-1 block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors?.customFields?.[index]?.label ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-white dark:bg-stone-900`}
                                />
                                {errors?.customFields?.[index]?.label && (
                                    <p className="mt-2 text-sm text-red-600">
                                        {errors.customFields[index].label.message}
                                    </p>
                                )}
                            </div>

                            <div>
                                <label>Response type</label>
                                <select
                                    {...register(`customFields.${index}.type`, {
                                        onChange: (e) => handleTypeChange(index, e.target.value)
                                    })}
                                    className="mt-1 block w-full rounded-sm border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 sm:text-sm disabled:bg-stone-50 disabled:text-stone-500 bg-white dark:bg-stone-900"
                                >
                                    <option value="text">Text</option>
                                    <option value="radio">Single choice (radio)</option>
                                    <option value="checkbox">Multi choice (checkboxes)</option>
                                </select>
                            </div>

                            {(fieldTypes[index]?.type === 'radio' ||
                                fieldTypes[index]?.type === 'checkbox') && (
                                <div className="sm:col-span-2">
                                    <label>Options (comma-separated)</label>
                                    <input
                                        type="text"
                                        {...register(`customFields.${index}.options`)}
                                        placeholder="Option 1, Option 2, Option 3"
                                        className="mt-1 block w-full rounded-sm border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 sm:text-sm disabled:bg-stone-50 disabled:text-stone-500 bg-white dark:bg-stone-900"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between pt-4">
                            <div className="flex items-center gap-x-3">
                                <input
                                    type="checkbox"
                                    {...register(`customFields.${index}.optional`)}
                                    className="h-4 w-4 rounded border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600 disabled:opacity-50"
                                />
                                <label>Optional</label>
                            </div>
                            <button
                                type="button"
                                onClick={() => removeCustomField(index)}
                                className="inline-flex items-center rounded-md bg-white dark:bg-stone-900 px-3 py-1 font-medium text-stone-900 dark:text-stone-100 ring-1 ring-inset ring-stone-300 dark:ring-stone-600 hover:bg-stone-50 dark:hover:bg-stone-800"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <button
                type="button"
                onClick={() => appendCustomField({
                    key: crypto.randomUUID(),
                    optional: true,
                    options: '',
                    label: '',
                    type: 'text'
                })}
                className="text-teal-600 hover:text-teal-800 border border-teal-600 rounded-md px-2 py-1 flex items-center gap-2"
            >
                <PlusIcon className="h-4 w-4" />
                Add attendee question
            </button>
        </div>
    )
}