import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyDMVjs_rLfunpqPTdkMAAIgPTjGDk0H4tM",
    authDomain: "sidebar-de524.firebaseapp.com",
    projectId: "sidebar-de524",
    storageBucket: "sidebar-de524.appspot.com",
    messagingSenderId: "732446536120",
    appId: "1:732446536120:web:164f2634ffae5f98049f4d"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app); 