import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { auth, db, functions } from './firebase'
import { onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import Homepage from './Homepage'
import Success from './Success'
import Terms from './Terms'
import Privacy from './Privacy'
import Ticket from './Ticket'
import ExpiredLoginLink from './ExpiredLoginLink'
import Upload from './Upload'
import AuthRequired from './components/auth/AuthRequired'
import AuthLoading from './AuthLoading'
import Thanks from './Thanks'
import Admin from './pages/Admin'
import Scanner from './pages/Scanner'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import { EmailSignIn } from './components/auth/EmailSignIn'
import { FinalizeSignIn } from './components/auth/FinalizeSignIn'

async function authenticateWithToken(token) {
    let decodedString
    try {
        decodedString = decodeURIComponent(window.atob(token))
    } catch (e) {
        decodedString = token
        console.log(e)
    }
    try {
        signInWithCustomToken(auth, decodedString)
    } catch (error) {
        console.log(error)
        throw error
    }
}

async function authenticateWithStripe(blob) {
    try {
        const decodedString = decodeURIComponent(window.atob(blob))
        const payload = JSON.parse(decodedString)
        const firebaseResponse = await fetch(`https://api.sidebarticketing.com/f/auth-with-stripe`, {
            method: 'POST',
            headers: {
                'Stripe-Signature': payload.signature,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: payload.userId,
                account_id: payload.accountId,
            }),
        })
        let token = false
        if (firebaseResponse.status === 200) {
            token = await firebaseResponse.text()
            if (token) signInWithCustomToken(auth, token)
        } else {
            console.log(firebaseResponse.status)
            throw new Error('Authentication failed')
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

function App() {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const urlParams = new URLSearchParams(window.location.search)

    useEffect(() => {
        return onAuthStateChanged(auth, (user) => {
            setUser(user)
            setLoading(false)
        })
    }, [])

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/signin" element={<EmailSignIn user={user} />} />
                <Route path="/finalize-signin" element={<FinalizeSignIn />} />
                <Route path="/thank-you" element={<Thanks />} />
                <Route path="/ticket/*" element={<Ticket />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/expired" element={<ExpiredLoginLink />} />
                <Route
                    path="/upload"
                    element={
                        user ? <Upload uid={user.uid} /> :
                            urlParams.has('x') ? <AuthLoading authenticateWith={() => authenticateWithStripe(urlParams.get('x'))} /> :
                                <AuthRequired />
                    }
                />
                <Route
                    path="/events/:eventId/scan"
                    element={
                        user ? (
                            <Scanner db={db} />
                        ) : (
                            <AuthRequired />
                        )
                    }
                />
                <Route
                    path="/*"
                    element={
                        urlParams.has('cid') ? (
                            <Success functions={functions} />
                        ) : user ? (
                            <Admin uid={user.uid} db={db} auth={auth} functions={functions} userEmail={user.email || ''} />
                        ) : urlParams.has('x') ? (
                            <AuthLoading authenticateWith={() => authenticateWithStripe(urlParams.get('x'))} />
                        ) : urlParams.has('t') ? (
                            <AuthLoading authenticateWith={() => authenticateWithToken(urlParams.get('t'))} />
                        ) : loading ? (
                            <AuthLoading />
                        ) : (
                            <Homepage />
                        )
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)