import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { collection, query, where, limit, getDocs, orderBy, getAggregateFromServer, sum, getDoc, doc } from "firebase/firestore";
import { TicketIcon, BoltIcon, BoltSlashIcon } from '@heroicons/react/24/outline'
import EventMenu from '../components/EventMenu'

export default function Event({ uid, events, auth, db, functions }) {
    const { eventId } = useParams()
    const [event, setEvent] = useState(events.find(e => e.paymentLinkId === eventId))
    const [loading, setLoading] = useState(!event)
    const [recentOrders, setRecentOrders] = useState([])
    const [totalSales, setTotalSales] = useState(0)
    const [totalFees, setTotalFees] = useState(0)
    const [timeSlots, setTimeSlots] = useState([])
    const [showFutureOnly, setShowFutureOnly] = useState(true)

    useEffect(() => {
        // Fetch recent orders
        const fetchRecentOrders = async () => {
            const q = query(
                collection(db, "orders"),
                where("accountId", "==", uid),
                where("paymentLinkId", "==", eventId),
                orderBy("orderDate", "desc"),
                limit(10)
            )

            try {
                const querySnapshot = await getDocs(q)
                setRecentOrders(querySnapshot.docs.map(doc => ({ ...doc.data() })))
            } catch (error) {
                console.error("Error fetching recent orders:", error)
            }
        }

        fetchRecentOrders()
    }, [db, uid, eventId])

    useEffect(() => {
        // Fetch total sales using aggregate query
        const fetchTotalSales = async () => {
            const q = query(
                collection(db, "orders"),
                where("accountId", "==", uid),
                where("paymentLinkId", "==", eventId)
            );

            try {
                const snapshot = await getAggregateFromServer(q, {
                    totalAmount: sum('orderTotal'),
                    totalFees: sum('ticketFee')
                })
                setTotalSales(snapshot.data().totalAmount || 0)
                setTotalFees(snapshot.data().totalFees || 0)
            } catch (error) {
                console.error("Error fetching totals:", error)
            }
        };

        fetchTotalSales()
    }, [db, uid, eventId])

    useEffect(() => {
        const fetchTimeSlots = async () => {
            if (!event?.timeSlotPatterns?.length) return
            try {
                const slotsRef = collection(db, "events", eventId, "slots")
                const querySnapshot = await getDocs(slotsRef)
                const slots = querySnapshot.docs
                    .map(doc => {
                        const [date, label] = doc.id.split('@')
                        return {
                            id: doc.id,
                            date,
                            label,
                            ...doc.data()
                        }
                    })
                    .filter(slot => slot.issued > 0)
                setTimeSlots(slots)
            } catch (error) {
                console.error("Full error:", error)
            }
        }
        fetchTimeSlots()
    }, [db, eventId, event?.timeSlotPatterns])

    useEffect(() => {
        const fetchArchivedEvent = async () => {
            if (event) return // Skip if event already exists

            try {
                const eventDoc = await getDoc(doc(db, "events", eventId))
                if (eventDoc.exists()) {
                    setEvent({ ...eventDoc.data(), paymentLinkId: eventId })
                } else {
                    // Event truly doesn't exist
                    setEvent(null)
                }
            } catch (error) {
                console.error("Error fetching archived event:", error)
                setEvent(null)
            } finally {
                setLoading(false)
            }
        }

        fetchArchivedEvent()
    }, [eventId, db, event])

    if (loading) {
        return <div>Loading...</div>
    }

    if (!event) {
        return <div>Event not found</div>
    }

    return (
        <div className="space-y-6">
            <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-2 md:gap-4">
                <div>
                    <h1 className="text-3xl font-bold">{event.eventName}</h1>
                    <a 
                        href={`https://tkt.page/${eventId}`}
                        target="_blank"
                        rel="noopener noreferrer" 
                        className="text-stone-500 dark:text-stone-400 hover:text-teal-600 dark:hover:text-teal-500"
                    >
                        tkt.page/{eventId}
                    </a>
                </div>
                <EventMenu 
                    eventId={eventId} 
                    menuId={`eventActions-${eventId}`}
                    issued={event.issued} 
                    auth={auth} 
                    uid={uid} 
                    functions={functions} 
                    events={events} 
                    db={db} 
                />
            </div>

            <dl className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {event.issued > 0 ? (
                    <div className="md:rounded-lg md:border md:border-stone-200 md:dark:border-stone-700 md:p-4 space-y-6">
                        <div className="flex justify-between items-center">
                            <dt className="truncate font-medium flex items-center gap-2">
                                <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-teal-600 text-white">
                                    <TicketIcon className="h-7 w-7" />
                                </span>
                                Attendees
                            </dt>
                            <Link
                                to={`/events/${eventId}/registrations`}
                                className="text-teal-600 hover:text-teal-900 border border-teal-600 rounded-full px-5 py-2"
                            >
                                Check in
                            </Link>
                        </div>
                        <div className="flex justify-between items-end">
                            <div>
                                <div className="flex items-baseline">
                                    <span className="text-3xl font-semibold tracking-tight">
                                        {event.issued || 0}
                                    </span>
                                    {event.capacity > 0 && !event.timeSlotPatterns?.length && (
                                        <span className="ml-2 text-xl font-medium text-stone-500 dark:text-stone-400">
                                            / {event.capacity}
                                        </span>
                                    )}
                                </div>
                                {event.capacity > 0 && !event.timeSlotPatterns?.length && (
                                    <div className="mt-2 h-2 w-full bg-stone-100 rounded-full overflow-hidden">
                                        <div
                                            className="h-full bg-teal-500 rounded-full transition-all duration-500"
                                            style={{ width: `${Math.min(100, (event.issued / event.capacity) * 100)}%` }}
                                        />
                                    </div>
                                )}
                                {event.timeSlotPatterns?.length > 0 && (
                                    <p className="text-stone-500 dark:text-stone-400 mt-1">Across all slots</p>
                                )}
                            </div>
                            {event.timeSlotPatterns?.length > 0 && (
                                <label className="flex items-center space-x-2 text-stone-500 dark:text-stone-400 mb-2">
                                    <input
                                        type="checkbox"
                                        checked={showFutureOnly}
                                        onChange={(e) => setShowFutureOnly(e.target.checked)}
                                        className="rounded border-stone-300 dark:border-stone-700 text-teal-600 focus:ring-teal-500 focus:ring-1"
                                    />
                                    <span>Future only</span>
                                </label>
                            )}
                        </div>
                        {/* Slots or Ticket Types */}
                        {event.timeSlotPatterns?.length > 0 ? (<>
                            <div className="hidden md:block"> {/* Desktop table view */}
                                <table className="min-w-full divide-y divide-stone-200 dark:divide-stone-700">
                                    <thead>
                                        <tr>
                                            <th className="text-left text-xs font-medium text-stone-500 dark:text-stone-400">Slot</th>
                                            <th className="text-right text-xs font-medium text-stone-500 dark:text-stone-400">Attendees</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-stone-200 dark:divide-stone-700">
                                        {timeSlots
                                            .filter(slot => !showFutureOnly || new Date(slot.date) >= new Date().setHours(0, 0, 0, 0))
                                            .map((slot) => (
                                                <tr key={slot.id}>
                                                    <td className="py-2 text-sm">
                                                        {new Date(slot.date).toLocaleDateString(undefined, {
                                                            weekday: 'short',
                                                            month: 'short',
                                                            day: 'numeric'
                                                        })} {slot.label}
                                                    </td>
                                                    <td className="py-2 text-sm text-stone-600 text-right">
                                                        <span className="font-medium">{slot.issued}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="md:hidden"> {/* Mobile card view */}
                                <div className="space-y-3">
                                    {timeSlots
                                        .filter(slot => !showFutureOnly || new Date(slot.date) >= new Date().setHours(0, 0, 0, 0))
                                        .map((slot) => (
                                            <div key={slot.id} className="p-3 rounded-lg border border-stone-200 dark:border-stone-700">
                                                <div className="flex justify-between items-center">
                                                    <div className="text-sm">
                                                        {new Date(slot.date).toLocaleDateString(undefined, {
                                                            weekday: 'short',
                                                            month: 'short',
                                                            day: 'numeric'
                                                        })} {slot.label}
                                                    </div>
                                                    <div className="text-sm font-medium">{slot.issued}</div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </>) : (
                            event.ticketTypes
                                .filter(type => (event[`issued_${type.productId}`] || 0) > 0 && type.name)
                                .map((type) => {
                                    const issuedCount = event[`issued_${type.productId}`] || 0;
                                    const inCartsCount = event[`inCarts_${type.productId}`] || 0;

                                    return (
                                        <div key={type.id} className="border-t border-stone-100 dark:border-stone-700 pt-4">
                                            <div className="flex justify-between items-center">
                                                <span className="font-medium max-w-60 truncate lg:max-w-none lg:truncate-none">{type.name}</span>
                                                <div className="flex items-center gap-2">
                                                    <span className="font-medium">{issuedCount}</span>
                                                    <span className="text-stone-500 dark:text-stone-400">
                                                        {type.capacity > 0 ? `/ ${type.capacity}` : ''}
                                                    </span>
                                                    {inCartsCount > 0 && (
                                                        <span className="text-xs text-stone-500 dark:text-stone-400">
                                                            (+{inCartsCount} in cart)
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                        )}
                    </div>
                ) : (
                    <div className="text-center space-y-2 flex flex-col justify-center rounded-lg border border-stone-200 dark:border-stone-700 px-6 py-14">
                        <TicketIcon className="w-12 h-12 mx-auto text-stone-500 dark:text-stone-400" />
                        <h3 className="font-medium">No attendees yet</h3>
                        <p className="text-stone-500 dark:text-stone-400">A list of attendees will appear here</p>
                    </div>
                )}

                {recentOrders.length > 0 ? (
                    <div className="md:rounded-lg md:border md:border-stone-200 md:dark:border-stone-700 md:p-4 space-y-6 mt-12 md:mt-0">
                        <div className="flex justify-between items-center">
                            <dt className="truncate font-medium flex items-center gap-2">
                                <span className={`inline-flex h-10 w-10 items-center justify-center rounded-full text-white ${event.isTicketingLive ? 'bg-teal-600' : 'bg-stone-500'}`}>
                                    {event.isTicketingLive ? <BoltIcon className="h-7 w-7" /> : <BoltSlashIcon className="h-7 w-7" />}
                                </span>
                                {event.isTicketingLive ? 'Sales Active' : 'Sales Paused'}
                            </dt>
                            <Link
                                to={`/orders?event=${eventId}`}
                                className="text-teal-600 hover:text-teal-900 border border-teal-600 rounded-full px-5 py-2"
                            >
                                View Orders
                            </Link>
                        </div>
                        <div>
                        <div className="flex items-baseline">
                            <span className="text-3xl font-semibold tracking-tight">
                                {new Intl.NumberFormat(undefined, {
                                    style: 'currency',
                                    currency: event.currency || 'USD'
                                }).format((totalSales - totalFees) / 100)}
                            </span>
                        </div>
                        <p className="text-stone-500 dark:text-stone-400 mt-1">net of ticketing fees</p>
                        </div>
                        <div className="hidden md:block"> {/* Desktop table view */}
                            <table className="min-w-full divide-y divide-stone-200 dark:divide-stone-700">
                                <thead>
                                    <tr>
                                        <th className="text-left text-xs font-medium text-stone-500 dark:text-stone-400">Name</th>
                                        <th className="text-left text-xs font-medium text-stone-500 dark:text-stone-400">Total</th>
                                        <th className="text-right text-xs font-medium text-stone-500 dark:text-stone-400">Date</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-stone-200 dark:divide-stone-700">
                                    {recentOrders.map((order) => (
                                        <tr key={order.paymentIntentId}>
                                            <td className="py-2">{order.buyerName}</td>
                                            <td className="py-2">
                                                {new Intl.NumberFormat(undefined, {
                                                    style: 'currency',
                                                    currency: order.currency || 'USD'
                                                }).format((order.orderTotal || 0) / 100)}
                                            </td>
                                            <td className="py-2 text-sm text-right">
                                                {order.orderDate._seconds
                                                    ? new Date(order.orderDate._seconds * 1000).toLocaleString()
                                                    : order.orderDate.toDate().toLocaleString()}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="md:hidden"> {/* Mobile card view */}
                            <div className="space-y-3">
                                {recentOrders.map((order) => (
                                    <div key={order.paymentIntentId} className="p-3 rounded-lg border border-stone-200 dark:border-stone-700">
                                        <div className="flex justify-between items-center mb-1">
                                            <div className="font-medium">{order.buyerName}</div>
                                            <div>
                                                {new Intl.NumberFormat(undefined, {
                                                    style: 'currency',
                                                    currency: order.currency || 'USD'
                                                }).format((order.orderTotal || 0) / 100)}
                                            </div>
                                        </div>
                                        <div className="text-sm text-stone-500 dark:text-stone-400">
                                            {order.orderDate._seconds
                                                ? new Date(order.orderDate._seconds * 1000).toLocaleString()
                                                : order.orderDate.toDate().toLocaleString()}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="text-center space-y-2 flex flex-col justify-center rounded-lg border border-stone-200 dark:border-stone-700 px-6 py-14">
                        <BoltIcon className="w-12 h-12 mx-auto text-stone-500 dark:text-stone-400" />
                        <h3 className="font-medium">No sales yet</h3>
                        <p className="text-stone-500 dark:text-stone-400">A list of orders will appear here</p>
                    </div>
                )}
            </dl>
        </div>
    )
} 