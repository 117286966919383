import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { format } from 'date-fns'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import Ticket from '../components/Ticket'

export default function Transaction({ db, uid, events, functions, accountDisplayName, accountPrimaryColor }) {
    const location = useLocation()
    const order = location.state?.order || {}
    const [tickets, setTickets] = useState([])
    const [loading, setLoading] = useState(true)
    const [event, setEvent] = useState(null)

    useEffect(() => {
        if (!order.paymentIntentId || !uid) return

        const q = query(
            collection(db, "tickets"),
            where("paymentIntentId", "==", order.paymentIntentId),
            where("accountId", "==", uid)
        )

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const tickets = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            if (tickets.length > 0) {
                const firstTicket = tickets[0];
                setEvent({
                    eventName: firstTicket.eventName,
                    eventStartDate: firstTicket.eventStartDate,
                    eventStartTime: firstTicket.eventStartTime,
                    eventEndDate: firstTicket.eventEndDate,
                    eventEndTime: firstTicket.eventEndTime,
                    selectedDate: firstTicket.selectedDate,
                    selectedTimeSlot: firstTicket.selectedTimeSlot,
                })
            }
            for (const t of tickets) {
                let dateString = `${getDateDisplayString(t)} ${getTimeDisplayString(t)}`.trim()
                if (t.selectedDate) {
                    const date = new Date(t.selectedDate)
                    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
                    dateString = `${formattedDate}${t.selectedTimeSlot && ` ${t.selectedTimeSlot}`}`
                }
                const obj = {
                    ticketName: t.description,
                    ticketId: t.id,
                    dateString: dateString,
                    eventName: t.eventName,
                    locationName: t.eventLocationName,
                    fullAddress: t.eventFullAddress,
                    accountDisplayName: accountDisplayName,
                    accountId: uid,
                    primaryColor: accountPrimaryColor
                }
                const jsonString = JSON.stringify(obj)
                const base64Encoded = btoa(unescape(encodeURIComponent(jsonString)))
                const url = `https://sidebarticketing.com/ticket?t=${base64Encoded}`
                t.url = url
            }
            setTickets(tickets)
            setLoading(false)
        }, (error) => {
            console.error("Error fetching tickets:", error)
            setLoading(false)
        })

        return () => unsubscribe()
    }, [accountDisplayName, accountPrimaryColor, db, order.paymentIntentId, uid])

    const formatCurrency = (amount, currency = 'USD') => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency || 'USD'
        }).format(amount / 100)
    }

    const getDateDisplayString = (event) => {
        if (event.selectedDate) {
            const selectedDate = new Date(event.selectedDate)
            const dateString = selectedDate.toDateString()
            const parts = dateString.split(' ')
            const day = parseInt(parts[2], 10)
            return `${parts[1]} ${day}, ${parts[3]}`
        }

        if (!event.eventStartDate) {
            return ""
        }
        const startDateTime = new Date(`${event.eventStartDate}T${event.eventStartTime || "00:00"}`)
        const endDateTime = new Date(`${event.eventEndDate}T${event.eventEndTime || "00:00"}`)

        const startDateString = startDateTime.toDateString()
        const parts = startDateString.split(' ')
        const day = parseInt(parts[2], 10)
        const startDateStringFormatted = `${parts[1]} ${day}, ${parts[3]}`

        if ((startDateTime.toDateString() === endDateTime.toDateString()) || !event.eventEndDate) {
            return startDateStringFormatted
        } else {
            let hour = startDateTime.getHours()
            const minute = String(startDateTime.getMinutes()).padStart(2, '0')
            const ampm = hour >= 12 ? 'PM' : 'AM'
            if (hour > 12) {
                hour -= 12
            } else if (hour === 0) {
                hour = 12
            }
            const timeString = `${hour}:${minute} ${ampm}`
            return `${startDateStringFormatted}, ${timeString}`
        }
    }

    const getTimeDisplayString = (event) => {
        if (!event.eventEndDate) {
            return ""
        }
        const startDateTime = new Date(`${event.eventStartDate}T${event.eventStartTime || "00:00"}`)
        const endDateTime = new Date(`${event.eventEndDate}T${event.eventEndTime || "00:00"}`)

        const endDateString = endDateTime.toDateString()
        const partsEnd = endDateString.split(' ')
        const dayEnd = parseInt(partsEnd[2], 10)
        const endDateStringFormatted = `${partsEnd[1]} ${dayEnd}, ${partsEnd[3]}`

        let hourStart = startDateTime.getHours()
        const minuteStart = String(startDateTime.getMinutes()).padStart(2, '0')
        const ampmStart = hourStart >= 12 ? 'PM' : 'AM'
        if (hourStart > 12) {
            hourStart -= 12
        } else if (hourStart === 0) {
            hourStart = 12
        }
        const startTimeStringFormatted = `${hourStart}:${minuteStart} ${ampmStart}`

        let hourEnd = endDateTime.getHours()
        const minuteEnd = String(endDateTime.getMinutes()).padStart(2, '0')
        const ampmEnd = hourEnd >= 12 ? 'PM' : 'AM'
        if (hourEnd > 12) {
            hourEnd -= 12
        } else if (hourEnd === 0) {
            hourEnd = 12
        }
        const endTimeStringFormatted = `${hourEnd}:${minuteEnd} ${ampmEnd}`

        if ((startDateTime.toDateString() === endDateTime.toDateString()) && event.eventStartTime && event.eventEndTime) {
            return `${startTimeStringFormatted} - ${endTimeStringFormatted}`
        } else if (startDateTime.toDateString() === endDateTime.toDateString()) {
            return ""
        } else {
            return `${endDateStringFormatted}, ${endTimeStringFormatted}`
        }
    }

    const now = new Date()

    return (
        <div className="space-y-6">
            <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                <div className="flex items-center gap-2">
                    <h1 className="text-3xl font-bold">
                        {formatCurrency(order.orderTotal, order.currency || 'USD')}
                    </h1>
                    {order.livemode === false && (
                        <span className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                            test
                        </span>
                    )}
                </div>
                <a
                    href={`https://dashboard.stripe.com${order.livemode ? '' : '/test'}/payments/${order.paymentIntentId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center font-medium text-teal-700 hover:opacity-90 border border-teal-700 rounded-md px-5 py-2 mt-4 sm:mt-0"
                >
                    Refund in Stripe
                    <ArrowTopRightOnSquareIcon className="ml-1.5 h-4 w-4" />
                </a>
            </div>
            <h2>Order Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 rounded border border-stone-200 dark:border-stone-600 p-4">
                {order.buyerName && (
                    <div>
                        <label className="text-sm text-stone-500 dark:text-stone-400">Name</label>
                        <div>{order.buyerName}</div>
                    </div>
                )}
                {order.buyerEmail && (
                    <div>
                        <label className="text-sm text-stone-500 dark:text-stone-400">Email</label>
                        <div>{order.buyerEmail}</div>
                    </div>
                )}
                {order.orderDate && (
                    <div>
                        <label className="text-sm text-stone-500 dark:text-stone-400">Order Date</label>
                        <div>
                            {order.orderDate?.seconds ?
                                format(new Date(order.orderDate.seconds * 1000), 'PPP') :
                                format(new Date(order.orderDate), 'PPP')
                            }
                        </div>
                    </div>
                )}
                <div>
                    <label className="text-sm text-stone-500 dark:text-stone-400">Order Number</label>
                    <div>{order.paymentIntentId}</div>
                </div>
                {event && (
                    <div>
                        <label className="text-sm text-stone-500 dark:text-stone-400">Event Name</label>
                        <div>{event.eventName}</div>
                    </div>
                )}
                {event &&(event.eventStartDate || event.selectedDate) && (
                    <div>
                        <label className="text-sm text-stone-500 dark:text-stone-400">Event Date</label>
                        <div>
                            {getDateDisplayString(event)} {event.eventEndDate && getTimeDisplayString(event)} {event.selectedTimeSlot && event.selectedTimeSlot}
                        </div>
                    </div>
                )}
            </div>
            {order.customFields && order.customFields.length > 0 && (
                <>
                    <h2>Custom Field Responses</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 rounded border border-stone-200 dark:border-stone-600 p-4">
                        {order.customFields.map((field, index) => (
                            <div key={index}>
                                <label className="text-sm text-stone-500 dark:text-stone-400">{field.label}</label>
                                <div>{field.value}</div>
                            </div>
                        ))}
                    </div>
                </>
            )}
            {tickets.length > 0 && (
                <>
                    <h2>Tickets</h2>
                    {loading ? (
                        <div>Loading tickets...</div>
                    ) : (
                        <ul>
                            {tickets.map(ticket => (
                                <Ticket
                                    key={ticket.id}
                                    ticket={ticket}
                                    now={now}
                                    db={db}
                                    showCustomFields={false}
                                    events={events}
                                    functions={functions}
                                />
                            ))}
                        </ul>
                    )}
                </>
            )}
            <h2>Payout</h2>
            <div className="border rounded-md p-4 space-y-2 border-stone-200 dark:border-stone-600">
                {order.orderDiscount > 0 && (
                    <div className="flex justify-between">
                        <span>Subtotal</span>
                        <span>
                            {formatCurrency(order.orderSubtotal, order.currency)}
                        </span>
                    </div>
                )}
                {order.orderDiscount > 0 ? (
                    <div className="flex justify-between">
                        <span>Discount</span>
                        <span>
                            -{formatCurrency(order.orderDiscount, order.currency)}
                        </span>
                    </div>
                ) :
                    <div className="flex justify-between text-sm">
                        <span>Buyer paid</span>
                        <span>
                            {formatCurrency(order.orderTotal, order.currency)}
                        </span>
                    </div>
                }
                {order.ticketFee > 0 && (
                    <div className="flex justify-between">
                        <span>Ticket Fee</span>
                        <span>
                            -{formatCurrency(order.ticketFee, order.currency)}
                        </span>
                    </div>
                )}
                <div className="pt-2 border-t flex justify-between font-bold border-stone-200 dark:border-stone-600">
                    <span>Sent to your Stripe account</span>
                    <span>
                        {formatCurrency(order.orderTotal - (order.ticketFee || 0), order.currency)}
                    </span>
                </div>
            </div>
        </div>
    )
} 