import { useState, useEffect } from 'react'
import { httpsCallable } from 'firebase/functions'
import UserAccountIcon from '../components/userAccountIcon'
import { signOut } from 'firebase/auth'
import { useSearchParams } from 'react-router-dom'
import { initiateEmailUpdate, sendReauthenticationLink, completeReauth } from '../auth/AuthService'
import { doc, updateDoc, deleteField } from 'firebase/firestore'
import { ArrowUpOnSquareIcon, EnvelopeIcon, ChevronLeftIcon, ChevronDoubleLeftIcon } from '@heroicons/react/24/outline'
import ReauthDialog from '../components/ReauthDialog'

export default function Settings({ uid, userData, functions, auth, db }) {
    const [isRevoking, setIsRevoking] = useState(false)
    const [revokeStatusMessage, setRevokeStatusMessage] = useState('')
    const [hasEditedTaxSettings, setHasEditedTaxSettings] = useState(false)
    const [taxDescription, setTaxDescription] = useState("")
    const [taxPercentage, setTaxPercentage] = useState("")
    const [taxIsAddedOn, setTaxIsAddedOn] = useState()
    const [isTaxSaveLoading, setIsTaxSaveLoading] = useState(false)
    const [taxSaveMessage, setTaxSaveMessage] = useState("")
    const [isLoadingQr, setIsLoadingQr] = useState(false)
    const [emailChangeMessage, setEmailChangeMessage] = useState('')
    const [searchParams] = useSearchParams()
    const [showReauth, setShowReauth] = useState(false)
    const [dialogMode, setDialogMode] = useState('request')

    useEffect(() => {
        if (userData) {
            setTaxDescription(userData.salesTaxDescription || "")
            setTaxPercentage(userData.salesTaxPercentage || "")
            setTaxIsAddedOn(userData.salesTaxIsAddedOn)
        }
    }, [userData])

    useEffect(() => {
        const mode = searchParams.get('mode')
        if (mode === 'signIn') {
            completeReauth()
                .then(() => {
                    setShowReauth(true)
                    setDialogMode('update')
                    const newSearchParams = new URLSearchParams(searchParams)
                    newSearchParams.delete('mode')
                    window.history.replaceState({}, '', `${window.location.pathname}?${newSearchParams}`)
                })
                .catch(error => {
                    console.error('Error completing reauth:', error)
                    setEmailChangeMessage(error.message)
                })
        }
    }, [searchParams])

    const handleTaxPercentageChange = (value) => {
        setTaxPercentage(value)
        setHasEditedTaxSettings(true)
    }

    const handleTaxDescriptionChange = (value) => {
        setTaxDescription(value)
        setHasEditedTaxSettings(true)
    }

    const handleTaxAddOnStatusChange = (value) => {
        setTaxIsAddedOn(value)
        setHasEditedTaxSettings(true)
    }

    const saveTaxSettings = async () => {
        if (taxPercentage && (!Number(taxPercentage) || Number(taxPercentage) < 1)) {
            setTaxSaveMessage("If set, percentage must be a valid number above 1")
            setIsTaxSaveLoading(false)
            return
        }
        if (taxDescription && taxDescription.length > 50) {
            setTaxSaveMessage("Description is limited to 50 characters")
            setIsTaxSaveLoading(false)
            return
        }
        if (taxDescription && taxDescription.length < 3) {
            setTaxSaveMessage("If set, description must be at least 3 characters")
            setIsTaxSaveLoading(false)
            return
        }
        
        setIsTaxSaveLoading(true)
        try {
            const userRef = doc(db, 'users', uid)
            const updates = {}

            if (taxPercentage) {
                updates.salesTaxPercentage = Number(taxPercentage)
            } else {
                updates.salesTaxPercentage = deleteField()
            }

            if (taxDescription) {
                updates.salesTaxDescription = taxDescription
            } else {
                updates.salesTaxDescription = deleteField()
            }

            if (taxIsAddedOn !== undefined) {
                updates.salesTaxIsAddedOn = taxIsAddedOn
            } else {
                updates.salesTaxIsAddedOn = deleteField()
            }

            await updateDoc(userRef, updates)
            setTaxSaveMessage("Changes saved")
        } catch (error) {
            console.error('Error saving tax settings:', error)
            setTaxSaveMessage("There was an error")
        } finally {
            setIsTaxSaveLoading(false)
        }
    }

    const revoke = async () => {
        setIsRevoking(true)
        try {
            const revokeTokens = httpsCallable(functions, 'revokeTokens')
            await revokeTokens()
            await signOut(auth)
            setRevokeStatusMessage("All sessions signed out")
        } catch (error) {
            console.error('Error revoking sessions:', error)
            setRevokeStatusMessage("Error signing out of all sessions")
        } finally {
            setIsRevoking(false)
        }
    }

    async function generateLoginCode () {
        try {
            setIsLoadingQr(true)
            const getLoginToken = httpsCallable(functions,'getLoginToken')
            const result = await getLoginToken()
            if (result.data !== "error") {
                const encodedString = window.btoa(encodeURIComponent(result.data))
                const url = `${window.location.origin}?t=${encodedString}`
                await navigator.clipboard.writeText(url)
                alert("Link copied to clipboard. Share with caution as it will give immediate access to your account. The link will expire in 1 hour.")
            }
            setIsLoadingQr(false)
        } catch (error) {
            setIsLoadingQr(false)
            console.log(error)
        }
    }

    const changeLoginEmail = async () => {
        const mode = searchParams.get('mode')
        if (mode === 'signIn') {
            setDialogMode('update')
        } else {
            setDialogMode('request')
        }
        setShowReauth(true)
    }

    const handleReauthComplete = async (email) => {
        if (dialogMode === 'request') {
            await sendReauthenticationLink()
            setEmailChangeMessage('Check your inbox for a verification link')
        } else {
            await initiateEmailUpdate(email)
            setEmailChangeMessage('Verification email sent to ' + email)
        }
    }

    const handleSignOut = async () => {
        try {
            await signOut(auth)
        } catch (error) {
            console.error('Error signing out:', error)
        }
    }

    return (
        <>
            <ReauthDialog
                isOpen={showReauth}
                onClose={() => setShowReauth(false)}
                onConfirm={handleReauthComplete}
                mode={dialogMode}
            />
            
            <div className="space-y-6">
                <div className="flex items-baseline justify-between">
                    <h1 className="text-3xl font-bold">Settings</h1>
                </div>
                <div className="space-y-1">
                    <h2 className="font-semibold">Access & Security</h2>
                    <p className="text-stone-500 dark:text-stone-400">{userData.emailAddress}</p>
                </div>
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-1 max-w-sm">
                    <button
                        onClick={changeLoginEmail}
                        className="w-full text-teal-600 border border-teal-600 rounded px-4 py-2 flex items-center justify-between"
                    >
                        <span>Change Login Email</span>
                        <EnvelopeIcon className="w-5 h-5" />
                    </button>
                    {emailChangeMessage && (
                        <p className="text-yellow-600 text-center py-2">{emailChangeMessage}</p>
                    )}
                    {!isLoadingQr ? (
                        <button
                            onClick={generateLoginCode}
                            className="w-full text-teal-600 border border-teal-600 rounded px-4 py-2 flex items-center justify-between"
                        >
                            <span>Generate Sign-in Link</span>
                            <ArrowUpOnSquareIcon className="w-5 h-5" />
                        </button>
                    ) : (
                        <div className="text-center py-2 text-stone-500 dark:text-stone-400">Generating link...</div>
                    )}
                    <button
                        onClick={handleSignOut}
                        className="w-full text-teal-600 border border-teal-600 rounded px-4 py-2 flex items-center justify-between"
                    >
                        <span>Sign Out</span>
                        <ChevronLeftIcon className="w-5 h-5" />
                    </button>
                    {/* Session Management */}
                    {!isRevoking && !revokeStatusMessage && (
                        <button
                            onClick={revoke}
                            className="w-full text-teal-600 border border-teal-600 rounded px-4 py-2 flex items-center justify-between"
                        >
                            <span>Sign Out All Sessions</span>
                            <ChevronDoubleLeftIcon className="w-5 h-5" />
                        </button>
                    )}
                    {isRevoking && (
                        <div className="flex items-center justify-center gap-2 text-stone-500 dark:text-stone-400 py-2">
                            <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span>Signing out of all sessions...</span>
                        </div>
                    )}
                    {revokeStatusMessage && (
                        <p className="text-yellow-600 text-center py-2">{revokeStatusMessage}</p>
                    )}
                </div>
                <div className="space-y-1">
                    <h2 className="font-semibold mt-8">Details</h2>
                    <p className="text-stone-500 dark:text-stone-400">To maintain a consistent checkout experience, these settings are managed in Stripe</p>
                </div>
                <div className="space-y-4 rounded border border-stone-200 dark:border-stone-800 px-4 py-2">
                    <table>
                        <tbody className="divide-y divide-stone-200 dark:divide-stone-800">
                            {/* Name */}
                            <tr>
                                <td className="py-3 w-24">
                                    <span className="font-medium text-stone-500 dark:text-stone-400">Name</span>
                                </td>
                                <td className="py-3">
                                    <span >{userData.displayName}</span>
                                </td>
                                <td className="py-3 w-12 text-right">
                                    <a
                                        href="https://dashboard.stripe.com/settings/business-details"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900"
                                    >
                                        Edit
                                    </a>
                                </td>
                            </tr>

                            {/* Support */}
                            <tr>
                                <td className="py-3 w-24">
                                    <span className="font-medium text-stone-500 dark:text-stone-400">Support</span>
                                </td>
                                <td className="py-3">
                                    <span >{userData.supportEmailAddress || userData.emailAddress}</span>
                                </td>
                                <td className="py-3 w-12 text-right">
                                    <a
                                        href="https://dashboard.stripe.com/settings/business-details"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900"
                                    >
                                        Edit
                                    </a>
                                </td>
                            </tr>

                            {/* Currency */}
                            <tr>
                                <td className="py-3 w-24">
                                    <span className="font-medium text-stone-500 dark:text-stone-400">Currency</span>
                                </td>
                                <td className="py-3">
                                    <span >{userData.currency || 'USD'}</span>
                                </td>
                                <td className="py-3 w-12 text-right">
                                    <a
                                        href="https://dashboard.stripe.com/settings/payouts"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900"
                                    >
                                        Edit
                                    </a>
                                </td>
                            </tr>

                            {/* Time Zone */}
                            <tr>
                                <td className="py-3 w-24">
                                    <span className="font-medium text-stone-500 dark:text-stone-400">Time Zone</span>
                                </td>
                                <td className="py-3">
                                    <span >{userData.timeZone || 'America/New_York'}</span>
                                </td>
                                <td className="py-3 w-12 text-right">
                                    <a
                                        href="https://dashboard.stripe.com/settings/account"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900"
                                    >
                                        Edit
                                    </a>
                                </td>
                            </tr>

                            {/* Branding */}
                            <tr>
                                <td className="py-3 w-24">
                                    <span className="font-medium text-stone-500 dark:text-stone-400">Branding</span>
                                </td>
                                <td className="py-3">
                                    <div className="flex items-center gap-3">
                                        <UserAccountIcon uid={uid} />
                                        <div className="flex gap-2">
                                            {userData.primaryColor && (
                                                <div 
                                                    className="w-6 h-6 rounded border border-stone-200 dark:border-stone-800" 
                                                    style={{ backgroundColor: userData.primaryColor }}
                                                    title="Primary Color"
                                                />
                                            )}
                                            {userData.secondaryColor && (
                                                <div 
                                                    className="w-6 h-6 rounded border border-stone-200 dark:border-stone-800" 
                                                    style={{ backgroundColor: userData.secondaryColor }}
                                                    title="Button Color"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </td>
                                <td className="py-3 w-12 text-right">
                                    <a
                                        href="https://dashboard.stripe.com/settings/branding"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-900"
                                    >
                                        Edit
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="space-y-1">
                    <h2 className="font-semibold mt-8">Tax & Add-Ons</h2>
                    <p className="text-stone-500 dark:text-stone-400">A line item with this description and % will be added to all checkout sessions</p>
                </div>
                <div className="space-y-4 rounded border border-stone-200 dark:border-stone-800 p-4 ">
                    <div className="space-y-4">
                        <div className="max-w-sm">
                            <label className="block font-medium">Description</label>
                            <input
                                type="text"
                                value={taxDescription}
                                onChange={(e) => handleTaxDescriptionChange(e.target.value)}
                                className="mt-1 block w-full rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900"
                            />
                        </div>

                        <div className="flex items-end gap-2 max-w-32">
                            <div className="flex-1">
                                <label className="block font-medium">Percentage</label>
                                <input
                                    type="number"
                                    value={taxPercentage}
                                    onChange={(e) => handleTaxPercentageChange(e.target.value)}
                                    className="mt-1 block w-full rounded-md border-stone-300 dark:border-stone-600 focus:border-teal-500 focus:ring-teal-500 bg-white dark:bg-stone-900"
                                />
                            </div>
                            <span className="text-stone-500 dark:text-stone-400 mb-2">%</span>
                        </div>

                        <div className="space-y-2">
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="tax-type"
                                    checked={taxIsAddedOn === true}
                                    onChange={() => handleTaxAddOnStatusChange(true)}
                                    className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900"
                                />
                                <span className="ml-2">Add the amount to the order</span>
                            </label>
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="tax-type"
                                    checked={taxIsAddedOn === false}
                                    onChange={() => handleTaxAddOnStatusChange(false)}
                                    className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900"
                                />
                                <span className="ml-2">Display the amount which is already price-inclusive</span>
                            </label>
                        </div>

                        <div className="flex items-center justify-between pt-2">
                            <button
                                onClick={saveTaxSettings}
                                disabled={!hasEditedTaxSettings || isTaxSaveLoading}
                                className={`inline-flex text-teal-600 border border-teal-600 rounded px-4 py-2 ${
                                    !hasEditedTaxSettings || isTaxSaveLoading
                                        ? 'opacity-50 cursor-not-allowed'
                                        : 'hover:text-teal-800'
                                }`}
                            >
                                {isTaxSaveLoading ? (
                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                ) : null}
                                Save Tax & Add-Ons
                            </button>
                            {taxSaveMessage && (
                                <span className="text-stone-500 dark:text-stone-400">{taxSaveMessage}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}