import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { sendLoginLink } from '../../auth/AuthService'

export function EmailSignIn({ user }) {
    const navigate = useNavigate();
    
    useEffect(() => {
        if (user) {
            navigate('/app');
        }
    }, [user, navigate]);

    const [email, setEmail] = useState('');
    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectTo = searchParams.get('redirect') || '/app';

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);

        try {
            window.localStorage.setItem('redirectAfterAuth', redirectTo);
            await sendLoginLink(email);
            setIsSent(true);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (isSent) {
        return (
            <div className="min-h-screen py-24 lg:py-36">
                <div className="w-10/12 max-w-4xl mx-auto">
                <img className="h-9 lg:h-12 mb-6 sidebar-logo" alt="sidebar-ticketing-logo" />
                    <h1 className="text-4xl tracking-tight font-bold sm:text-7xl mx-auto">Check Your Email</h1>
                    <p className="mt-8 text-stone-600 dark:text-stone-400">
                        Click the sign-in link sent to <strong>{email}</strong>.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen py-24 lg:py-36">
            <div className="w-10/12 max-w-4xl mx-auto">
                <img className="h-9 lg:h-12 mb-6 sidebar-logo" alt="sidebar-ticketing-logo" />
                <div className="max-w-md">
                    <h1 className="text-4xl tracking-tight font-bold sm:text-7xl mx-auto">Hello 👋</h1>
                    <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-stone-700 dark:text-stone-300 mb-2"
                            >
                                Sign into an existing or brand new account
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-4 py-3 bg-white dark:bg-stone-900 border border-stone-300 dark:border-stone-700 rounded-lg focus:border-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-500 transition duration-150 ease-in-out"
                                placeholder="your@email.com"
                                required
                            />
                        </div>

                        {error && (
                            <div className="p-4 bg-red-50 border border-red-100 text-red-700 rounded-lg flex items-center">
                                <svg className="h-5 w-5 text-red-400 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" />
                                </svg>
                                {error}
                            </div>
                        )}

                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full py-3 px-4 rounded-lg text-white bg-teal-600 hover:bg-teal-500 font-semibold 
                            transition duration-150 ease-in-out transform hover:scale-[1.02] 
                            focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2
                            ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? (
                                <span className="flex items-center justify-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Sending...
                                </span>
                            ) : 'Send Sign-In Link'}
                        </button>

                        <p className="text-center text-sm text-stone-500 dark:text-stone-400 mt-4">
                            By signing in, you agree to our{' '}
                            <a href="/terms" className="text-teal-600 hover:text-teal-500">Terms of Service</a>
                            {' '}and{' '}
                            <a href="/privacy" className="text-teal-600 hover:text-teal-500">Privacy Policy</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
} 