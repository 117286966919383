import { useState, useEffect } from 'react'
import { doc, collection, query, where, orderBy, limit, onSnapshot, updateDoc } from 'firebase/firestore'
import AuthLoading from '../AuthLoading'
import Events from './Events'
import { Link, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Transactions from './Transactions'
import Settings from './Settings'
import Event from './Event'
import Transaction from './Transaction'
import Registrations from './Registrations'
import EventForm from './EventForm'
import EmptyState from '../components/EmptyState'
import UserAccountIcon from '../components/userAccountIcon'

export default function Admin({ uid, db, auth, functions, userEmail }) {
    const [userData, setUserData] = useState(null)
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(true)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const location = useLocation()

    useEffect(() => {
        // Create a subscription to listen for changes
        const unsubscribe = onSnapshot(doc(db, "users", uid),
            (userDocSnap) => {
                setUserData(userDocSnap.data())
                setLoading(false)
                if (userEmail && userDocSnap.exists() && userDocSnap.emailAddress !== userEmail) {
                    updateDoc(doc(db, "users", uid), {
                        emailAddress: userEmail
                    })
                }
            },
            (error) => {
                console.log(error)
                setLoading(false)
            }
        )

        // Clean up subscription when component unmounts
        return () => unsubscribe()
    }, [userEmail, db, uid])

    useEffect(() => {
        const q = query(collection(db, "events"),
            where("accountId", "==", uid),
            where("archived", "==", false),
            orderBy("lastModified", "desc"),
            limit(250)
        )

        // Create a subscription to listen for changes
        const unsubscribe = onSnapshot(q,
            (querySnapshot) => {
                const newEvents = querySnapshot.docs.map(doc => ({
                    paymentLinkId: doc.id,
                    ...doc.data()
                }))
                setEvents(newEvents)
            },
            (error) => {
                console.log('Error loading events:', error)
            }
        )

        return () => unsubscribe()
    }, [db, uid])

    // Update the click handler to also close mobile menu
    useEffect(() => {
        function handleClick(event) {
            const menu = document.getElementById('mainMenu')
            const menuButton = event.target.closest('button[data-dropdown="mainMenu"]')
            const mobileMenuButton = event.target.closest('button[aria-label="Open menu"]')
            
            if (!menuButton && !mobileMenuButton && !menu?.contains(event.target)) {
                setIsMobileMenuOpen(false)
            }
        }

        document.addEventListener('click', handleClick)
        return () => document.removeEventListener('click', handleClick)
    }, [])

    // if (events.length === 0) {
    //     return <EmptyState auth={auth} />
    // }

    const navigation = [
        { name: 'Events', path: '/events' },
        { name: 'Orders', path: '/orders' },
        { name: 'Settings', path: '/settings' },
    ]

    return (
        <div className="min-h-screen flex">
            {/* Mobile Hamburger Button */}
            <div className="md:hidden fixed top-2 right-4 z-50">
                <button
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    className="h-11 w-11 inline-flex items-center justify-center bg-white dark:bg-stone-900 border border-stone-300 dark:border-stone-800 rounded-md shadow-sm text-stone-600 dark:text-stone-400 hover:text-stone-900 dark:hover:text-stone-100"
                    aria-label="Open menu"
                >
                    <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                        {isMobileMenuOpen ? (
                            <path d="M6 18L18 6M6 6l12 12" />
                        ) : (
                            <path d="M4 6h16M4 12h16M4 18h16" />
                        )}
                    </svg>
                </button>
            </div>

            {/* Mobile Menu Overlay */}
            <div className={`md:hidden fixed inset-0 bg-stone-800/50 z-40 transition-opacity duration-200 ${isMobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                <div className={`fixed inset-y-0 right-0 w-64 bg-white dark:bg-stone-950 transform transition-transform duration-200 ease-in-out ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                    <nav className="p-4 pt-16 space-y-2" aria-label="Mobile Navigation">
                        {navigation.map((item) => {
                            const isActive = location.pathname === '/' 
                                ? item.path === '/events'
                                : location.pathname.startsWith(item.path)
                            return (
                                <Link
                                    key={item.name}
                                    to={item.path}
                                    onClick={() => setIsMobileMenuOpen(false)}
                                    className={`
                                        block px-4 py-2 font-medium rounded-full
                                        transition-all duration-200 ease-in-out
                                        ${isActive 
                                            ? 'text-teal-700 dark:text-teal-50 bg-teal-50 dark:bg-teal-900 border border-teal-700 dark:border-teal-700 hover:opacity-90' 
                                            : 'text-stone-600 dark:text-stone-400 bg-white dark:bg-black border border-stone-200 dark:border-stone-700 hover:opacity-90'
                                        }
                                    `}
                                >
                                    {item.name}
                                </Link>
                            )
                        })}
                    </nav>
                </div>
            </div>

            {/* Sidebar for desktop */}
            <div className="hidden md:fixed md:flex md:top-0 md:left-0 md:h-screen md:w-36 md:p-4 md:pt-9 md:flex-col">
                <Link to="/" className="flex items-center mb-8 justify-center">
                    <UserAccountIcon uid={uid} />
                </Link>
                
                <nav className="space-y-1" aria-label="Main Navigation">
                    {navigation.map((item) => {
                        const isActive = location.pathname === '/' 
                            ? item.path === '/events'
                            : location.pathname.startsWith(item.path)
                        return (
                            <Link
                                key={item.name}
                                to={item.path}
                                className={`
                                    flex items-center px-5 py-2 font-medium rounded-full
                                    transition-all duration-200 ease-in-out
                                    ${isActive 
                                        ? 'text-teal-700 dark:text-teal-50 bg-teal-50 dark:bg-teal-900 border border-teal-700 dark:border-teal-700 hover:opacity-90' 
                                        : 'text-stone-600 dark:text-stone-400 bg-white dark:bg-black border border-stone-200 dark:border-stone-700 hover:opacity-90'
                                    }
                                `}
                            >
                                {item.name}
                            </Link>
                        )
                    })}
                </nav>
            </div>

            {/* Main Content - remove the top margin */}
            <div className="flex-1 min-h-screen md:ml-36">
                <div className="md:p-3 h-full">
                    <div className="bg-white dark:bg-black md:rounded-xl md:border md:border-stone-200 md:dark:border-stone-700 h-full p-4 sm:p-8">
                        {loading ? <AuthLoading /> : (
                            <Routes>
                                <Route path="/" element={events.length === 0 ? <EmptyState stripeAccountId={userData?.stripeAccountId} /> : <Events events={events} db={db} uid={uid} functions={functions} />} />
                                <Route path="events" element={events.length === 0 ? <EmptyState stripeAccountId={userData?.stripeAccountId} /> : <Events events={events} db={db} uid={uid} functions={functions} />} />
                                <Route path="events/new" element={<EventForm uid={uid} currency={userData?.currency || 'USD'} chargesEnabled={userData?.stripeConnectChargesEnabled || false} stripeAccountId={userData?.stripeAccountId} />} />
                                <Route path="events/:eventId" element={<Event events={events} auth={auth} uid={uid} db={db} functions={functions} />} />
                                <Route path="events/:eventId/edit" element={<EventForm events={events} currency={userData?.currency || 'USD'} chargesEnabled={userData?.stripeConnectChargesEnabled || false} uid={uid} stripeAccountId={userData?.stripeAccountId} />} />
                                <Route path="events/:eventId/registrations" element={<Registrations uid={uid} db={db} events={events} functions={functions} auth={auth} accountDisplayName={userData?.displayName} accountPrimaryColor={userData?.primaryColor} />} />
                                <Route path="orders" element={<Transactions uid={uid} db={db} events={events} functions={functions} />} />
                                <Route path="orders/:transactionId" element={<Transaction uid={uid} db={db} events={events} functions={functions} accountDisplayName={userData?.displayName} accountPrimaryColor={userData?.primaryColor} />} />
                                <Route path="settings" element={<Settings uid={uid} userData={userData} functions={functions} auth={auth} db={db} />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Routes>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
