import { useFieldArray } from 'react-hook-form'
import { PlusIcon, ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

export default function Dates({ control, register, getValues, setValue, errors }) {
    const [, setUpdateCounter] = useState(0)

    const { fields: patterns, append: appendPattern, remove: removePattern } = useFieldArray({
        control,
        name: "timeSlotPatterns"
    })

    const { append: appendSlot, remove: removeSlot } = useFieldArray({
        control,
        name: `timeSlotPatterns.${patterns.length - 1}.slots`
    })

    return (
        <div className="space-y-2">
            <hr className="my-4 dark:border-stone-700" />
            <div className="space-y-4">
                {patterns.length === 0 ? (
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                            <label>Start date</label>
                            <div className="mt-1">
                                <input
                                    type="date"
                                    {...register('eventStartDate')}
                                    className={`block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors.eventStartDate ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                                />
                                {errors.eventStartDate && (
                                    <p className="mt-2 text-sm text-red-600">
                                        {errors.eventStartDate.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div>
                            <label>Start time</label>
                            <div className="mt-1">
                                <input
                                    type="time"
                                    {...register('eventStartTime')}
                                    className={`block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors.eventStartTime ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                                />
                            </div>
                        </div>
                        <div>
                            <label>End date</label>
                            <div className="mt-1">
                                <input
                                    type="date"
                                    {...register('eventEndDate')}
                                    className={`block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors.eventEndDate ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                                />
                                {errors.eventEndDate && (
                                    <p className="mt-2 text-sm text-red-600">
                                        {errors.eventEndDate.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div>
                            <label>End time</label>
                            <div className="mt-1">
                                <input
                                    type="time"
                                    {...register('eventEndTime')}
                                    className={`block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors.eventEndTime ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-6">
                        <div className="space-y-2">
                            <div>
                                <label>Recurring start</label>
                                <p className="text-sm text-stone-500">Leave blank to start now</p>
                            </div>
                            <input
                                type="date"
                                {...register('eventStartDate')}
                                className={`block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors.eventStartDate ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                            />
                            {errors.eventStartDate && (
                                <p className="mt-2 text-sm text-red-600">
                                    {errors.eventStartDate.message}
                                </p>
                            )}
                        </div>
                        <div className="space-y-2">
                            <div>
                                <label>Recurring end</label>
                                <p className="text-sm text-stone-500">Leave blank for a rolling 6 months</p>
                            </div>
                            <input
                                type="date"
                                {...register('eventEndDate')}
                                className={`block w-full rounded-sm border-0 py-1.5 ring-1 ring-inset ${errors.eventEndDate ? 'ring-red-300' : 'ring-stone-300 dark:ring-stone-600'} placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 disabled:bg-stone-50 disabled:text-stone-500 sm:text-sm sm:leading-6 bg-stone-50 dark:bg-stone-900`}
                            />
                            {errors.eventEndDate && (
                                <p className="mt-2 text-sm text-red-600">
                                    {errors.eventEndDate.message}
                                </p>
                            )}
                        </div>
                    </div>
                )}
                <div className="sm:col-span-2 space-y-6">
                    {patterns.map((pattern, patternIndex) => (
                        <div key={pattern.id} className="rounded-md border p-4 bg-stone-50 dark:bg-stone-800 space-y-4 divide-y divide-stone-200 dark:divide-stone-700 dark:border-stone-700">
                            <div className="text-xs font-semibold uppercase">
                                Pattern
                            </div>
                            <div className="space-y-4 pt-4">
                                <div className="space-y-4">
                                    <div className="flex gap-4">
                                        <label className="flex items-center gap-2">
                                            <input
                                                type="radio"
                                                checked={!getValues(`timeSlotPatterns.${patternIndex}.isSpecificDate`)}
                                                onChange={() => {
                                                    setValue(`timeSlotPatterns.${patternIndex}.isSpecificDate`, false)
                                                    setValue(`timeSlotPatterns.${patternIndex}.date`, '')
                                                    setUpdateCounter(c => c + 1)
                                                }}
                                                className="h-4 w-4 border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600"
                                            />
                                            <span>Days of week</span>
                                        </label>
                                        <label className="flex items-center gap-2">
                                            <input
                                                type="radio"
                                                checked={getValues(`timeSlotPatterns.${patternIndex}.isSpecificDate`)}
                                                onChange={() => {
                                                    setValue(`timeSlotPatterns.${patternIndex}.isSpecificDate`, true)
                                                    setValue(`timeSlotPatterns.${patternIndex}.days`, [])
                                                    setUpdateCounter(c => c + 1)
                                                }}
                                                className="h-4 w-4 border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600"
                                            />
                                            <span>Specific date</span>
                                        </label>
                                    </div>
                                    {!getValues(`timeSlotPatterns.${patternIndex}.isSpecificDate`) ? (
                                        <div className="flex gap-4 py-2">
                                            {[
                                                { value: 1, label: 'Mon' },
                                                { value: 2, label: 'Tue' },
                                                { value: 3, label: 'Wed' },
                                                { value: 4, label: 'Thu' },
                                                { value: 5, label: 'Fri' },
                                                { value: 6, label: 'Sat' },
                                                { value: 0, label: 'Sun' }
                                            ].map(day => {
                                                const days = getValues(`timeSlotPatterns.${patternIndex}.days`) || []
                                                return (
                                                    <label key={day.value} className="flex items-center gap-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={days.includes(day.value)}
                                                            onChange={() => {
                                                                const newDays = days.includes(day.value)
                                                                    ? days.filter(d => d !== day.value)
                                                                    : [...days, day.value].sort((a, b) => a - b)
                                                                
                                                                setValue(`timeSlotPatterns.${patternIndex}.days`, newDays)
                                                                // needed to force re-render
                                                                setUpdateCounter(c => c + 1)
                                                            }}
                                                            className="h-4 w-4 rounded border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 text-teal-600 focus:ring-teal-600"
                                                        />
                                                        <span>{day.label}</span>
                                                    </label>
                                                )
                                            })}
                                        </div>
                                    ) : (
                                        <input
                                            type="date"
                                            {...register(`timeSlotPatterns.${patternIndex}.date`)}
                                            className="w-full block rounded-sm border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                                        />
                                    )}
                                </div>
                                <div className="space-y-2">
                                    {(getValues(`timeSlotPatterns.${patternIndex}.slots`) || []).map((slot, slotIndex) => (
                                        <div key={slot.key || slotIndex} className="flex items-center gap-2">
                                            <div className="flex items-center gap-2 flex-1">
                                                <input
                                                    type="time"
                                                    {...register(`timeSlotPatterns.${patternIndex}.slots.${slotIndex}.start`)}
                                                    className="w-32 rounded-sm border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                                                    readOnly
                                                    onClick={(e) => {
                                                        e.target.readOnly = false
                                                        e.target.focus()
                                                    }}
                                                />
                                                <span className="text-stone-500">-</span>
                                                <input
                                                    type="time"
                                                    {...register(`timeSlotPatterns.${patternIndex}.slots.${slotIndex}.end`)}
                                                    className="w-32 rounded-sm border-stone-300 dark:border-stone-600 bg-stone-50 dark:bg-stone-900 focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                                                    readOnly
                                                    onClick={(e) => {
                                                        e.target.readOnly = false
                                                        e.target.focus()
                                                    }}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => removeSlot(slotIndex)}
                                                    className="inline-flex items-center"
                                                >
                                                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const slots = getValues(`timeSlotPatterns.${patternIndex}.slots`) || [];
                                            const lastSlot = slots[slots.length - 1];
                                            const startTime = lastSlot?.end || "09:00";
                                            
                                            // Calculate end time (1 hour after start time)
                                            const [hours, minutes] = startTime.split(':');
                                            const endHours = (parseInt(hours) + 1).toString().padStart(2, '0');
                                            const endTime = `${endHours}:${minutes}`;

                                            appendSlot({
                                                key: Date.now().toString(),
                                                start: startTime,
                                                end: endTime
                                            });
                                        }}
                                        className="text-teal-600 hover:text-teal-800 border border-teal-600 rounded-md px-2 py-1 flex items-center gap-2"
                                    >
                                        <PlusIcon className="h-4 w-4" /> {(getValues(`timeSlotPatterns.${patternIndex}.slots`) || []).length > 0 ? 'Add slot' : 'Use time slots'}
                                    </button>
                                </div>  
                            </div>
                            <div className="flex justify-between pt-4">
                                <button
                                    type="button"
                                    onClick={() => {
                                        const currentPattern = getValues(`timeSlotPatterns.${patternIndex}`);
                                        appendPattern({
                                            ...currentPattern,
                                            key: Date.now().toString(),
                                            slots: currentPattern.slots
                                        });
                                    }}
                                    className="inline-flex items-center rounded-md bg-white dark:bg-stone-900 px-3 py-1 font-medium text-stone-900 dark:text-stone-100 ring-1 ring-inset ring-stone-300 dark:ring-stone-600 hover:bg-stone-50 dark:hover:bg-stone-800"
                                >
                                    Duplicate
                                </button>
                                <button
                                    type="button"
                                    onClick={() => removePattern(patternIndex)}
                                    className="inline-flex items-center rounded-md bg-white dark:bg-stone-900 px-3 py-1 font-medium text-stone-900 dark:text-stone-100 ring-1 ring-inset ring-stone-300 dark:ring-stone-600 hover:bg-stone-50 dark:hover:bg-stone-800"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault()
                            appendPattern({
                                key: Date.now().toString(),
                                date: "",
                                days: [],
                                slots: [],
                                isSpecificDate: false
                            })
                        }}
                        className="text-teal-600 hover:text-teal-800 border border-teal-600 rounded-md px-2 py-1 flex items-center gap-2"
                    >
                        {patterns.length === 0 ? <ArrowPathIcon className="h-4 w-4" /> : <PlusIcon className="h-4 w-4" />}
                        {patterns.length === 0 ? "Make it recurring" : "Add pattern"}
                    </button>
                </div>
            </div>
        </div>
    )
}