import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

export default function ReauthDialog({ isOpen, onClose, onConfirm, mode = 'request' }) {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError('')
        
        if (mode === 'update') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (!emailRegex.test(email)) {
                setError("Please enter a valid email address")
                return
            }
        }

        setIsLoading(true)
        try {
            await onConfirm(email)
            setEmail('')
            onClose()
        } catch (error) {
            setError(error.message || 'An error occurred. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Dialog open={isOpen} onClose={onClose} className="relative z-50">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="mx-auto max-w-sm rounded bg-white dark:bg-neutral-900 p-6 w-full">
                    <div className="flex justify-between items-center mb-4">
                        <Dialog.Title className="text-lg font-medium">
                            {mode === 'request' ? 'Verify Identity' : 'Change Email Address'}
                        </Dialog.Title>
                        <button onClick={onClose} className="text-neutral-500">
                            <XMarkIcon className="h-5 w-5" />
                        </button>
                    </div>

                    {mode === 'request' ? (
                        <div className="space-y-4">
                            <p className="text-sm text-neutral-600 dark:text-neutral-400">
                                For security, we'll send a verification link to your current email address. 
                                Click the link to continue changing your email.
                            </p>
                            <button
                                onClick={handleSubmit}
                                disabled={isLoading}
                                className="w-full bg-teal-600 text-white rounded-md py-2 px-4 text-sm font-medium hover:bg-teal-700 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isLoading ? 'Sending...' : 'Send Verification Link'}
                            </button>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                                    New Email Address
                                </label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full rounded-md border-neutral-300 dark:border-neutral-700 dark:bg-neutral-800"
                                    placeholder="Enter your new email address"
                                    required
                                />
                            </div>

                            {error && (
                                <p className="text-sm text-red-600">{error}</p>
                            )}

                            <button
                                type="submit"
                                disabled={!email || isLoading}
                                className="w-full bg-teal-600 text-white rounded-md py-2 px-4 text-sm font-medium hover:bg-teal-700 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isLoading ? 'Updating...' : 'Update Email'}
                            </button>
                        </form>
                    )}
                </Dialog.Panel>
            </div>
        </Dialog>
    )
} 