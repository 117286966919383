import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import BasicDetails from '../components/EventForm/BasicDetails'
import Dates from '../components/EventForm/Dates'
import TicketTypes from '../components/EventForm/TicketTypes'
import CustomFields from '../components/EventForm/CustomFields'
import { getFunctions, httpsCallable } from 'firebase/functions'
import OptionalDetails from '../components/EventForm/OptionalDetails'
import { useState } from 'react'

const functions = getFunctions()

export default function EventForm({ events = [], uid, currency, chargesEnabled, stripeAccountId }) {
    const { eventId } = useParams()
    const navigate = useNavigate()
    const event = eventId ? events.find(e => e.paymentLinkId === eventId) : null
    const isNewEvent = !eventId

    const [isSimpleMode, setIsSimpleMode] = useState(() => {
        if (!event) return true
        return !(event.ticketTypes.length > 1 || (event.ticketTypes.length === 1 && event.ticketTypes[0].name))
    })

    const { register, handleSubmit, getValues, setValue, control, watch, formState: { errors } } = useForm({
        defaultValues: {
            eventName: event?.eventName || '',
            eventLocationName: event?.eventLocationName || '',
            eventFullAddress: event?.eventFullAddress || '',
            eventStartDate: event?.eventStartDate || '',
            eventStartTime: event?.eventStartTime || '',
            eventEndDate: event?.eventEndDate || '',
            eventEndTime: event?.eventEndTime || '',
            eventCheckinType: event?.eventCheckinType || 'tickets',
            allowPromoCodes: event?.allowPromoCodes || false,
            ticketTypes: event?.ticketTypes?.map(ticket => ({
                ...ticket,
                price: ticket.price ? (ticket.price / 100) : '',
                capacity: ticket.capacity === -1 ? '' : ticket.capacity
            })) || [{
                price: '',
                maxQty: 1,
                visible: true,
                capacity: '',
                description: '',
                key: Date.now().toString()
            }],
            customFields: event?.customFields || [],
            timeSlotPatterns: event?.timeSlotPatterns || [],
            eventCapacity: event?.capacity === -1 ? '' : event?.capacity || '',
            eventDescription: event?.eventDescription || '',
            eventInstructions: event?.eventInstructions || '',
            primaryColor: event?.primaryColor || '#000000',
            secondaryColor: event?.secondaryColor || '#000000',
            eventImageUrl: event?.eventImageUrl || '',
            buyerPaysFees: event?.buyerPaysFees || false,
            collectPhoneNumber: event?.collectPhoneNumber || false,
            collectAddress: event?.collectAddress || false,
            livemode: event?.livemode || true,
        },
        resolver: async (data) => {
            const errors = {}
            
            // Basic field validations
            if (!data.eventName) {
                errors.eventName = { type: 'required', message: 'Event name is required' }
            } else if (data.eventName.length > 100) {
                errors.eventName = { type: 'maxLength', message: 'Event name cannot exceed 100 characters' }
            }

            // Date validations
            const startDateTime = new Date(`${data.eventStartDate} ${data.eventStartTime}`)
            const endDateTime = new Date(`${data.eventEndDate} ${data.eventEndTime}`)
            const now = new Date()

            if (startDateTime < now) {
                errors.eventStartDate = { type: 'future', message: 'Start date must be in the future' }
            }

            if (endDateTime < startDateTime) {
                errors.eventEndDate = { type: 'dateComparison', message: 'End date/time cannot be before start date/time' }
            }

            // Ticket validations
            if (data.ticketTypes && !isSimpleMode) {
                data.ticketTypes.forEach((ticket, index) => {
                    if (!ticket.name) {
                        if (!errors.ticketTypes) errors.ticketTypes = []
                        errors.ticketTypes[index] = { name: { type: 'required', message: 'Ticket name is required' } }
                    } else if (ticket.name.length > 100) {
                        if (!errors.ticketTypes) errors.ticketTypes = []
                        errors.ticketTypes[index] = { name: { type: 'maxLength', message: 'Ticket name cannot exceed 100 characters' } }
                    }
                    
                    if (ticket.description && ticket.description.length > 400) {
                        if (!errors.ticketTypes) errors.ticketTypes = []
                        errors.ticketTypes[index] = { 
                            ...errors.ticketTypes[index],
                            description: { type: 'maxLength', message: 'Description cannot exceed 400 characters' }
                        }
                    }
                })
            }

            // Other field validations
            if (data.eventDescription?.length > 1000) {
                errors.eventDescription = { type: 'maxLength', message: 'Description cannot exceed 1000 characters' }
            }

            if (data.eventLocationName?.length > 100) {
                errors.eventLocationName = { type: 'maxLength', message: 'Location name cannot exceed 100 characters' }
            }

            if (data.eventFullAddress?.length > 200) {
                errors.eventFullAddress = { type: 'maxLength', message: 'Address cannot exceed 200 characters' }
            }

            if (data.eventInstructions?.length > 1500) {
                errors.eventInstructions = { type: 'maxLength', message: 'Instructions cannot exceed 1500 characters' }
            }

            if (data.customFields) {
                data.customFields.forEach((field, index) => {
                    if (field.label && field.label.length > 200) {
                        if (!errors.customFields) errors.customFields = []
                        errors.customFields[index] = { 
                            label: { type: 'maxLength', message: 'Question cannot exceed 200 characters' }
                        }
                    }
                })
            }

            return {
                values: Object.keys(errors).length === 0 ? data : {},
                errors
            }
        }
    })

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [savedEventId, setSavedEventId] = useState(null)

    const onSubmit = async (data) => {
        try {
            setIsSubmitting(true)
            setIsSuccess(false)
            const postEventForm = httpsCallable(functions, 'postEventForm')
            
            if (!isNewEvent) {
                data.eventId = eventId
            }

            const formattedData = {
                ...data,
                ticketTypes: data.ticketTypes.map(ticket => ({
                    ...ticket,
                    price: ticket.price ? Math.round(ticket.price * 100) : ticket.price
                }))
            }
            
            const result = await postEventForm(formattedData)
            console.log(result)
            setSavedEventId(isNewEvent ? result.data : eventId)
            setIsSuccess(true)
        } catch (error) {
            console.error('Error saving event:', error)
            // TODO: Add error notification
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">
                    {isNewEvent ? 'New Event' : 'Edit Event'}
                </h1>
            </div>
            <div className="max-w-4xl">
                <form className="space-y-8">
                    <BasicDetails
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        uid={uid}
                        errors={errors}
                    />
                    <Dates
                        control={control}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        errors={errors}
                    />
                    <TicketTypes
                        control={control}
                        register={register}
                        watch={watch}
                        currency={currency}
                        chargesEnabled={chargesEnabled}
                        errors={errors}
                        isSimpleMode={isSimpleMode}
                        setIsSimpleMode={setIsSimpleMode}
                        stripeAccountId={stripeAccountId}
                    />
                    <OptionalDetails
                        register={register}
                        errors={errors}
                        chargesEnabled={chargesEnabled}
                        watch={watch}
                    />
                    <CustomFields
                        control={control}
                        register={register}
                        getValues={getValues}
                        errors={errors}
                        setValue={setValue}
                    />
                    <hr className="my-4 dark:border-stone-700" />
                    <div className="flex justify-between gap-2 items-center">
                        <button
                            type="button"
                            onClick={() => navigate('/events')}
                            className="inline-flex items-center rounded-md bg-white dark:bg-stone-900 px-4 py-2 font-semibold text-stone-900 dark:text-stone-100 ring-1 ring-inset ring-stone-300 dark:ring-stone-600 hover:bg-stone-50 dark:hover:bg-stone-800"
                        >
                            Cancel
                        </button>
                        <div className="flex items-center gap-4">
                            {isSuccess && (
                                <span className="text-teal-600 text-sm flex items-center gap-2">
                                    Success. 
                                    <a 
                                        href={`https://tkt.page/${savedEventId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-teal-600 hover:text-teal-500 underline"
                                    >
                                        View event
                                    </a>
                                </span>
                            )}
                            <button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                disabled={isSubmitting}
                                className="inline-flex items-center rounded-md bg-teal-700 px-4 py-2 font-semibold text-white shadow-sm hover:bg-teal-600 disabled:bg-teal-300 disabled:cursor-not-allowed"
                            >
                                {isSubmitting ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Saving...
                                    </>
                                ) : (
                                    'Save event'
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
} 